export default {
    NO_TRAIN_LINES:
        'There are no train lines within 30 minutes of this location.',
    INCOMPLETE_LOCATION:
        'Could not fully resolve location. Address may be invalid.',
    BAD_GEOCODER_STATUS:
        'Could no resolve location. Address is most likely invalid.',
    NO_SUCH_PROPERTY_ID: 'A property with this ID does not exist!',
    UNABLE_TO_LOAD_DATA: 'Unable to load data from SRE API',
};
