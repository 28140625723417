module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (_.isEmpty(activities)) { ;
__p += '\n<p>Client has no recent activities.</p>\n';
 } else { ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th></th>\n            <th>Message</th>\n            <th>Timestamp</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 activities.forEach((activity) => { ;
__p += '\n        <tr>\n            <td>' +
((__t = ( activity.icon )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( activity.message )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( activity.log.timestamp.format('YYYY-MM-DD HH:mm') )) == null ? '' : __t) +
'</td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n';
 } ;


}
return __p
};
