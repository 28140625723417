import async from 'async';
import { View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import uploader from '@/js/app/file-uploader/uploader';
import template from '../templates/edit_media.html';
import placeholderTemplate from '../templates/edit_media_placeholder.html';
import PropertyEditMediaItemView from './edit-media-item';

export default class PropertyEditMediaView extends View {
    #mode = 'read';
    #draggedEl;
    #dropIndex;

    preinitialize(options) {
        this.events = {
            'click .btn-file-upload': this.openFileSelector,
            'change .file-selector': this.uploadFile,
            'dragstart .media-item': this.handleDragStart,
            'dragenter .media-item:not(.placeholder)': this.handleDragEnter,
            'dragover .placeholder': this.handleDragOver,
            'drop .placeholder': this.handleDrop,
        };

        // Pickup options
        this.structure_type = options.structure_type;

        // Create subviews
        this.subviews = {
            mediaItems: [],
        };

        // Create placeholder element from template
        this.placeholderEl = new DOMParser().parseFromString(
            placeholderTemplate(),
            'text/html',
        ).body.firstChild;
    }

    initialize() {
        _.bindAll(
            this,
            'addMediaItem',
            'removeMediaItem',
            'handleDragStart',
            'handleDragEnd',
        );

        // When model added to collection; add media item
        this.listenTo(this.collection, 'add', this.addMediaItem);

        // When model removed from collection; remove media item
        this.listenTo(this.collection, 'remove', this.removeMediaItem);

        // When collection reset; render
        this.listenTo(this.collection, 'reset', this.render);
    }

    render() {
        console.debug('PropertyEditMediaView#render');

        this.el.innerHTML = template({
            structure_type: this.model.get('structure_type'),
        });

        // Add media item for each item in collection
        this.collection.each(this.addMediaItem);

        populateForm(this.el, this.model.toJSON());

        this.#initializeReadEditMode();

        return this;
    }

    switchReadMode() {
        this.#mode = 'read';

        this.#initializeReadEditMode();
    }

    switchEditMode() {
        this.#mode = 'edit';

        this.#initializeReadEditMode();
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            this.el
                .querySelectorAll('input,select,textarea')
                .forEach((el) => (el.disabled = true));

            // Disable "Photo Upload" button
            this.el.querySelector('.btn-file-upload').disabled = true;

            // Disable fields on each media item
            this.subviews.mediaItems.forEach((view) => view.switchReadMode());
        } else if (this.#mode === 'edit') {
            this.el
                .querySelectorAll('input,select,textarea')
                .forEach((el) => (el.disabled = false));

            // Enable "Photo Upload" button
            this.el.querySelector('.btn-file-upload').disabled = false;

            // Enable fields on each media item
            this.subviews.mediaItems.forEach((view) => view.switchEditMode());
        }
    }

    addMediaItem(model, collection, options = {}) {
        console.debug('PropertyEditMediaView#addMediaItem');

        // Create media item view with model
        const mediaItem = new PropertyEditMediaItemView({
            model: model,
            building_id: this.model.get('l_id'),
            unit_id:
                this.model.get('structure_type') === 'unit'
                    ? this.model.get('id')
                    : undefined,
            structure_type: this.model.get('structure_type'),
        });

        // Push item view to array
        this.subviews.mediaItems.push(mediaItem);

        // Append div to media-item-list
        this.el
            .querySelector('.media-item-list')
            .appendChild(mediaItem.render().el);

        // If edit mode, switch to edit mode
        if (options.mode === 'edit') {
            mediaItem.switchEditMode();
        }
    }

    removeMediaItem(model, collection, options) {
        console.debug('PropertyEditMediaView#removeMediaItem');

        // Remove associated subview from position options.index
        this.subviews.mediaItems.splice(options.index, 1);
    }

    openFileSelector() {
        console.debug('PropertyEditMediaView#openFileSelector');

        // Trigger click on file upload input
        this.$el.find('.file-selector').trigger('click');
    }

    uploadFile($e) {
        console.debug('PropertyEditMediaView#uploadFile');

        const files = $e.currentTarget.files;
        const uploadStatusContainer = this.$el.find('.cntr-upload-status');

        // Clear upload status container
        uploadStatusContainer.empty();

        // Create file upload queue with handler
        const q = async.queue((file, callback) => {
            // Upload file
            /**
             * Moving forward, file uploader could either connect to a collection, or only connect to the file upload API.
             * This would negate the need for extra parameters, like propertyId
             */
            uploader(
                file,
                {
                    method: 'POST',
                    url: this.model.url() + '/images',
                },
                {
                    uploadStatusContainer: uploadStatusContainer,
                },
            )
                .then((response) => {
                    // Add new image model to collection using response data
                    const model = new this.collection.model(response);
                    model.imageFile = file;

                    // Add model to collection
                    this.collection.add(model, { mode: 'edit' });
                })
                .then(callback)
                .catch(callback);
        }, 1);

        // Loop through the FileList
        for (const file of files) {
            // Add file to queue
            q.push(file);
        }

        // Clear file selector
        $e.currentTarget.value = '';
    }

    handleDragStart($e) {
        console.debug('PropertyEditMediaView#handleDragStart');

        // Create reference to dragged element
        this.#draggedEl = $e.currentTarget;

        // Add handler for dragend event (won't necessarily be in DOM, so need to attach handler directly)
        this.#draggedEl.addEventListener('dragend', this.handleDragEnd);

        // Record index of dragged element as default drop index
        this.#dropIndex = this.#getIndexOfElement(this.#draggedEl);

        // Note: Must set timeout due to Chrome issue when manipulating DOM in drag related event
        setTimeout(() => {
            // Insert placeholder before dragged media item (as if to replace it)
            this.el
                .querySelector('.media-item-list')
                .insertBefore(this.placeholderEl, this.#draggedEl);

            // Remove element from DOM
            this.#draggedEl.remove();
        });
    }

    handleDragEnd() {
        console.debug('PropertyEditMediaView#handleDragEnd');

        // Remove placeholder element from DOM
        this.placeholderEl.remove();

        const containerEl = this.el.querySelector('.media-item-list');

        // Reattach dragged element to DOM
        containerEl.appendChild(this.#draggedEl);

        // Move dragged element to recorded drop index
        this.el
            .querySelector('.media-item-list')
            .insertBefore(
                this.#draggedEl,
                containerEl.children[this.#dropIndex],
            );

        // Reset reference to dragged element and drop index
        this.#draggedEl = undefined;
        this.#dropIndex = undefined;
    }

    handleDragEnter($e) {
        console.debug('PropertyEditMediaView#onDragEnter');

        // Get el of media item entered
        const mediaItemEl = $e.currentTarget;

        // Get index of element amongst siblings
        const elIndex = this.#getIndexOfElement(mediaItemEl);

        // Get index of placeholder amongst siblings
        const placeholderIndex = this.#getIndexOfElement(this.placeholderEl);

        // If placeholder is currently before item being entered
        if (placeholderIndex < elIndex) {
            // Move placeholder after media item being entered
            // Note: this will move the item being entered away from the mouse pointer, triggering a dragLeave event
            this.el
                .querySelector('.media-item-list')
                .insertBefore(this.placeholderEl, mediaItemEl.nextSibling);
        }
        // Else if placeholder is after item being entered
        else if (placeholderIndex > elIndex) {
            // Move placeholder before media item being entered
            // Note: this will move the item being entered away from the mouse pointer, triggering a dragLeave event
            this.el
                .querySelector('.media-item-list')
                .insertBefore(this.placeholderEl, mediaItemEl);
        }
    }

    handleDragOver($e) {
        // Note: this event is fired continuously and cannot be debounced or throttled, otherwise the drop event won't fire
        $e.preventDefault();
        $e.originalEvent.dataTransfer.dropEffect = 'move';
    }

    handleDrop($e) {
        console.debug('PropertyEditMediaView#handleDrop');

        $e.preventDefault();

        const currentIndex = this.#getIndexOfElement(this.placeholderEl);
        const initialIndex = this.#dropIndex;

        // If current index is different from initial index (eg view being dropped in a different location)
        if (currentIndex !== initialIndex) {
            // Set drop index use by "dragend" handler
            this.#dropIndex = currentIndex;

            this.#changeCollectionOrder(initialIndex, currentIndex);

            this.#changeSubviewOrder(initialIndex, currentIndex);
        }
    }

    #changeSubviewOrder(fromIndex, toIndex) {
        console.debug('PropertyEditMediaView#changeSubviewPosition');

        // Remove view from subview array
        const movedSubview = this.subviews.mediaItems.splice(fromIndex, 1);

        // Insert view on dropzoneIndex
        this.subviews.mediaItems.splice(toIndex, 0, ...movedSubview);
    }

    #changeCollectionOrder(fromIndex, toIndex) {
        console.debug('PropertyEditMediaView#changeCollectionOrder');

        // Remove model from collection (silently)
        const model = this.collection.remove(this.collection.at(fromIndex), {
            silent: true,
        });

        // Add model back to collection at index of dropzone (silently)
        this.collection.add(model, {
            at: toIndex,
            silent: true,
        });
    }

    #getIndexOfElement(element) {
        return [...element.parentNode.children].indexOf(element);
    }
}
