module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="input-group" data-part="typeahead">\n    <input class="form-control typeahead" type="text" placeholder="&#xf002 ' +
((__t = ( placeholder )) == null ? '' : __t) +
'" autocomplete="off" ' +
((__t = ( required ? 'required' : '' )) == null ? '' : __t) +
'>\n</div>\n<div class="input-group d-none" data-part="display">\n    <input class="form-control" type="text" readonly>\n    <div class="input-group-append">\n        <span class="input-group-text">\n            <a href="#" data-action="delete"><i class="fa fa-times fa-lg" style="color:#000000" aria-hidden="true"></i></a>\n        </span>\n    </div>\n    <div class="input-group-append">\n        <span class="input-group-text">\n            <a data-outlet="link"><i class="fa fa-external-link" aria-hidden="true"></i></a>\n        </span>\n    </div>\n</div>';

}
return __p
};
