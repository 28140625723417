import { View } from 'backbone';
import template from '../templates/typeahead.html';

export default class Typeahead extends View {
    #mode = '';

    preinitialize(options) {
        this.events = {
            'typeahead:select .typeahead': this.handleSelect,
            'click [data-action="delete"]': this.handleClearClick,
        };

        this.placeholder = options.placeholder;
        this.required = options.required;
        this.value = options.value;
        this.options = options.options;
        this.datasets = options.datasets;
        this.datum = options.datum;
        this.formatter = options.formatter;
        this.urlGenerator = options.urlGenerator;
        this.showDelete = options.showDelete || true;
    }

    initialize() {
        _.bindAll(this, 'render');
    }

    render() {
        console.debug('UiTypeaheadView#render');

        this.el.innerHTML = template({
            placeholder: this.placeholder,
            required: this.required,
        });

        // Setup typeahead field
        this.$typeaheadEl = this.$el.find('.typeahead');
        this.$typeaheadEl.typeahead(this.options, this.datasets);

        // Set value
        this.setValue(this.value);

        this.#initializeReadEditMode();

        return this;
    }

    switchReadMode() {
        this.#mode = 'read';

        this.#initializeReadEditMode();
    }

    switchEditMode() {
        this.#mode = 'edit';

        this.#initializeReadEditMode();
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            this.#disableInput();

            if (this.showDelete) {
                this.#hideDeleteLink();
            }
        } else if (this.#mode === 'edit') {
            const currentValue = this.$typeaheadEl.val();
            if (currentValue) {
                this.#disableInput();

                if (this.showDelete) {
                    this.#showDeleteLink();
                }
            } else {
                this.#enableInput();

                if (this.showDelete) {
                    this.#hideDeleteLink();
                }
            }
        }
    }

    setValue(value) {
        console.debug('UiTypeaheadView#setValue');

        // Set value of typeahead field
        this.$typeaheadEl.typeahead('val', value);

        // Set value of display field
        this.el.querySelector('input[type="text"][readonly]').value = value;

        if (value) {
            this.#hideTypeaheadField();
            this.#showDisplayField();
        } else {
            this.#hideDisplayField();
            this.#showTypeaheadField();
        }
    }

    setDatum(datum) {
        console.debug('UiTypeaheadView#selectDatum');

        // Set datum in view
        this.datum = datum;

        // Update value
        if (typeof this.formatter === 'function') {
            this.setValue(this.formatter(datum));
        } else {
            console.debug(
                'Call to setDatum() without a formatter function set on Typeahead instance. Make sure to call setValue() explicitly.',
            );
        }

        // Update link URL
        if (typeof this.urlGenerator === 'function') {
            this.setLinkURL(this.urlGenerator(datum));
        }

        this.#initializeReadEditMode();
    }

    handleSelect($e, datum) {
        console.debug('UiTypeaheadView#select');

        // Trigger "select" with original event and datum as parameters
        this.trigger('select', $e, this.datum);

        this.setDatum(datum);
    }

    setLinkURL(url) {
        this.el.querySelector('[data-outlet="link"]').href = url;
    }

    handleClearClick($e) {
        console.debug('UiTypeaheadView#clear');

        $e.preventDefault();

        this.clear();

        // Trigger "clear" with original event as parameter
        this.trigger('clear', $e);
    }

    clear() {
        // Clear datum in view
        this.datum = null;

        this.#hideDisplayField();
        this.#showTypeaheadField();

        if (this.showDelete) {
            this.#hideDeleteLink();
        }

        // Clear link
        this.setLinkURL('');

        // Clear and enable typeahead
        this.$typeaheadEl.typeahead('val', '').prop('disabled', false).focus();
    }

    #showTypeaheadField() {
        this.el
            .querySelector('[data-part="typeahead"]')
            .classList.remove('d-none');
    }

    #hideTypeaheadField() {
        this.el
            .querySelector('[data-part="typeahead"]')
            .classList.add('d-none');
    }

    #showDisplayField() {
        this.el
            .querySelector('[data-part="display"]')
            .classList.remove('d-none');
    }

    #hideDisplayField() {
        this.el.querySelector('[data-part="display"]').classList.add('d-none');
    }

    #enableInput() {
        this.$typeaheadEl.prop('disabled', false);
    }

    #disableInput() {
        this.$typeaheadEl.prop('disabled', true);
    }

    #showDeleteLink() {
        this.$el
            .find('[data-action="delete"]')
            .closest('.input-group-append')
            .removeClass('d-none');
    }

    #hideDeleteLink() {
        this.$el
            .find('[data-action="delete"]')
            .closest('.input-group-append')
            .addClass('d-none');
    }
}
