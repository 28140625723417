import * as Formatter from '@/js/app/formatter';
import { View } from 'backbone';
import template from '../templates/revenue.html';

export default class DealRevenueView extends View {
    initialize() {
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('DealRevenueView#render');

        this.el.innerHTML = template({
            revenue: this.collection.toJSON(),
            Formatter: Formatter,
        });

        return this;
    }
}
