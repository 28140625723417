const TypeaheadDefault = {
    hint: false,
    highlight: false,
    minLength: 2,
    classNames: {
        input: 'tt-input form-control',
    },
};

export default TypeaheadDefault;

export const TypeaheadCompanyDefaults = _.extend(TypeaheadDefault, {});

export const TypeaheadStaffDefaults = _.extend(TypeaheadDefault, {});
