import { Collection } from 'backbone';
import config from '@/js/app/config';
import UnitFileModel from '../models/unit-file';

export default Collection.extend({
    url: function () {
        return config.api.url + 'units/' + this.unit_id + '/files';
    },

    initialize: function (models, options = {}) {
        this.unit_id = options.unit_id || null;
    },

    model: UnitFileModel,
});
