import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import Session from '@/js/app/session';
import template from '../templates/blank.html';

export default class DashboardBlankView extends View {
    render() {
        console.debug('DashboardBlankView#render');

        this.el.innerHTML = template({
            Formatter,
            Session,
        });

        return this;
    }
}
