import { Events } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

export default class InquiriesReportResultModel {
    constructor() {
        _.extend(this, Events);

        this.data = null;
    }

    fetch() {
        this.trigger('request');

        OAuth2Client.ajax({
            url: config.api.url + 'reports/inquiries',
            dataType: 'json',
        }).then((response) => {
            this.trigger('request_done');
            this.data = response;

            this.trigger('change');
        });
    }

    toJSON() {
        return this.data;
    }
}
