import { View } from 'backbone';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import template from '../templates/agent_owner_name_selector.html';

export default class AgentPmNameSelector extends View {
    preinitialize(options) {
        this.events = {
            'change select': this.handleSelectChange,
        };

        this.subviews = {
            individualSelector: new UiIndividualSelectorView({
                collection: options.collection,
                attributes: {
                    name: options.name,
                },
            }),
        };
    }

    initialize() {
        _.bindAll(this, 'handleSelectChange');
    }

    render() {
        this.el.innerHTML = template();

        this.$el
            .find('[data-slot="selector"]')
            .html(this.subviews.individualSelector.el);

        return this;
    }

    clear() {
        // Clear selector
        this.subviews.individualSelector.clear();

        // Hide phone number
        this.el.querySelector('[data-outlet="telephone"]').innerText = '';
    }

    fetch(company_id) {
        company_id = Number(company_id);

        // If company ID is 0; return
        if (company_id === 0 || isNaN(company_id)) {
            return;
        }

        this.collection.fetch({
            data: {
                company_id,
                order: 'last_name',
            },
        });
    }

    setValue(value) {
        this.subviews.individualSelector.setSelected(value);
    }

    handleSelectChange(e) {
        // Get record selected
        const id = e.currentTarget.value;
        const record = this.collection.get(id);

        if (record) {
            this.updateTelephone(record);
        }
    }

    updateTelephone(record) {
        // Update outlet
        this.el.querySelector('[data-outlet="telephone"]').innerText =
            record.get('telephone');
    }
}
