import { View } from 'backbone';
import Chart from 'chart';
import numeral from 'numeral';
import config from '@/js/app/config';
import { getName } from '@/js/app/formatter';
import template from '../templates/summary_division.html';

export default class ReportRevenueDivisionSummaryView extends View {
    initialize() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('ReportRevenueDivisionSummaryView#render');

        const data = this.model.toJSON();

        // Render template and attach to this.$el
        this.el.innerHTML = template({
            results: data[0],
        });

        // Chart settings
        const chart_data = {
            datasets: [
                {
                    data: [],
                    borderWidth: 0,
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                },
            ],
            labels: [],
        };

        // Filter staff without revenue
        const filteredStaff = data[0].staff.filter(
            (s) => s.kpis.revenue.actual > 0,
        );

        const colors = config.brand_colors.hsv(
            filteredStaff.length >= 5 ? filteredStaff.length : 5,
            'short',
        );

        // Add revenue data and labels to chart
        filteredStaff.forEach((s, index) => {
            const color = Object.create(colors[index]);

            // Add data to dataset
            chart_data.datasets[0].data.push(s.kpis.revenue.actual);
            chart_data.datasets[0].backgroundColor.push(color.toRgbString());
            chart_data.datasets[0].hoverBackgroundColor.push(
                color.brighten().toRgbString(),
            );

            // Add label
            chart_data.labels.push(getName(s));

            index++;
        });

        const ctx = document
            .getElementById('chart_division_summary')
            .getContext('2d');
        new Chart(ctx, {
            type: 'pie',
            data: chart_data,
            options: {
                easing: 'easeOutQuint',
                legend: {
                    display: false,
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let dataLabel = data.labels[tooltipItem.index];
                            let value =
                                ': ' +
                                numeral(
                                    data.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index],
                                ).format('0,0');

                            if (_.isArray(dataLabel)) {
                                // show value on first line of multiline label
                                // need to clone because we are changing the value
                                dataLabel = dataLabel.slice();
                                dataLabel[0] += value;
                            } else {
                                dataLabel += value;
                            }

                            return dataLabel;
                        },
                    },
                },
            },
        });

        return this;
    }
}
