import { Events } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

export default class DealSummaryCollection {
    constructor() {
        _.extend(this, Events);
        this.data = [];
    }

    fetch(options) {
        this.trigger('request');

        OAuth2Client.ajax(
            _.extend(
                {
                    url: config.api.url + 'deals/summaries',
                    dataType: 'json',
                },
                options,
            ),
        ).then((response) => {
            this.data = response;

            this.trigger('change');

            this.trigger('sync');
        });
    }

    toJSON() {
        return this.data;
    }
}
