import { View } from 'backbone';
import PeopleCollection from '@/js/app/people/collections/people';
import Session from '@/js/app/session';
import ClientCriteria from '../criterias/client';
import template from '../templates/mine.html';
import ClientResultsView from './results';

export default class ClientMineView extends View {
    preinitialize() {
        // Create criteria model
        this.criteria = new ClientCriteria();

        // Create subviews
        this.subviews = {
            clientsResults: new ClientResultsView({
                collection: new PeopleCollection(),
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        this.render();

        // Set criteria
        this.criteria.set({
            assigned_staff_id: Session.data.staff_id,
        });
    }

    render() {
        console.debug('ClientMineView#render');

        // Render template
        this.el.innerHTML = template();

        // Attach clients results view to DOM
        this.subviews.clientsResults
            .setElement(this.$el.find('#results'))
            .render();

        return this;
    }
}
