import { View } from 'backbone';
import Criteria from '@/js/app/criteria-model';
import DealSummaryCollection from '../collections/summary';
import template from '../templates/summary.html';
import SummaryResultsView from './summary-results';

export default class DealSummaryView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'deal';

        this.timeSeries = '';
        this.date_to = null;
        this.date_from = null;

        this.tableHeaders = [];

        this.events = {
            'submit form': this.handleSubmit,
            'change select[name="summary_type"]': this.handleSummaryTypeChange,
            'change input[name="time_series"]': this.handleTimeSeriesChange,
        };

        // Create criteria model
        this.criteria = new Criteria();

        this.collection = new DealSummaryCollection();

        // Create subviews
        this.subviews = {
            summaryResults: new SummaryResultsView({
                collection: this.collection,
                criteria: this.criteria,
                tableHeaders: this.tableHeaders,
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'handleSubmit');

        this.render();
    }

    render() {
        console.debug('DealSummaryView#render');

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const yearArray = [];

        for (let i = 2017; i <= currentYear + 10; i++) {
            yearArray.push(i);
        }

        // Render template
        this.el.innerHTML = template({
            time_series: this.timeSeries,
            year_array: yearArray,
        });

        // Attach summaryResults subview to DOM
        this.subviews.summaryResults.setElement(
            this.el.querySelector('#results'),
        );

        this.priceInputsEl = this.el.querySelector('#price-inputs');
        this.dateInputsEl = this.el.querySelector('#date-inputs');

        return this;
    }

    handleSubmit($e) {
        console.debug('DealSummaryView#handleSubmit');

        $e.preventDefault();

        // Get criteria from form
        const criteria = this.criteria.getFormData($e.currentTarget);

        // Clear empty fields
        Object.keys(criteria).forEach((key) => {
            if (criteria[key] === '') {
                delete criteria[key];
            }
        });

        // Clear existing criteria, and set new criteria
        this.criteria.clear({ silent: true }).set(criteria);
    }

    handleSummaryTypeChange($e) {
        const filterType = $e.target.value;

        const {
            inputGroup: aggregatorInputGroup,
            inputElements: aggregatorInputs,
        } = this.getInputElements('#aggregator-input');

        const { inputGroup: budgetInputGroup, inputElements: budgetInputs } =
            this.getInputElements('#budget-input');

        if (filterType === 'country_buyer' || filterType === 'country_seller') {
            this.disableInputElements(aggregatorInputGroup, aggregatorInputs);
            this.disableInputElements(budgetInputGroup, budgetInputs);
        } else {
            this.enableInputElements(aggregatorInputGroup, aggregatorInputs);
            this.enableInputElements(budgetInputGroup, budgetInputs);
        }
    }

    handleTimeSeriesChange($e) {
        this.timeSeries = $e.target.value;

        const { inputGroup: monthlyInputGroup, inputElements: monthlyInputs } =
            this.getInputElements('#monthly-inputs');

        const { inputGroup: yearlyInputGroup, inputElements: yearlyInputs } =
            this.getInputElements('#yearly-inputs');

        if (this.timeSeries === 'monthly') {
            this.enableInputElements(monthlyInputGroup, monthlyInputs);
            this.disableInputElements(yearlyInputGroup, yearlyInputs);
        } else if (this.timeSeries === 'yearly') {
            this.enableInputElements(yearlyInputGroup, yearlyInputs);
            this.disableInputElements(monthlyInputGroup, monthlyInputs);
        } else {
            this.disableInputElements(yearlyInputGroup, yearlyInputs);
            this.disableInputElements(monthlyInputGroup, monthlyInputs);
        }
    }

    /**
     * @param {string} groupSelector Element identifier to be used as querySelector target.
     */
    getInputElements(groupSelector) {
        const inputGroup = this.el.querySelector(groupSelector);
        const inputElements = inputGroup.querySelectorAll('.form-control');

        return { inputGroup, inputElements };
    }

    /**
     * @param {HTMLElement} inputGroup HTML element to hide.
     * @param {NodeList} inputs NodeList containing input elements to disable.
     */
    disableInputElements(inputGroup, inputs) {
        inputGroup.classList.add('d-none');

        inputs.forEach((input) => {
            input.disabled = true;
        });
    }

    /**
     * @param {HTMLElement} inputGroup HTML element to show.
     * @param {NodeList} inputs NodeList containing input elements to enable.
     */
    enableInputElements(inputGroup, inputs) {
        inputGroup.classList.remove('d-none');

        inputs.forEach((input) => {
            input.disabled = false;
        });
    }
}
