import { View } from 'backbone';
import modalTemplate from './templates/modal.html';

export default class ModalView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'modal fade';
        this.events = {
            ...this.events,
            'hidden.bs.modal': this.teardown,
        };
    }

    render(options = { size: false, centered: false, scrollable: false }) {
        console.debug('Modal#render');

        this.el.innerHTML = modalTemplate({
            size: false,
            centered: false,
            scrollable: false,
            ...options,
        });

        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: true,
        });

        this.modalContent = this.el.querySelector('.modal-content');

        return this;
    }

    teardown() {
        console.debug('Modal#teardown');

        this.$el.data('modal', null);
        this.remove();
    }
}
