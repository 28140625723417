import { history, View } from 'backbone';
import BloodhoundIndividualDataset from '@/js/app/bloodhound/datasets/individual';
import BuildingSearchResultsView from '@/js/app/building/views/search-results';
import Preferences from '@/js/app/preferences';
import propertyRestoreSelectedService from '@/js/app/property/services/restore-selected';
import PropertySearchResultsView from '@/js/app/property/views/search-results';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import TypeaheadDefault from '@/js/app/typeahead/defaults';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import PropertyListCollection from '../collections/property-lists';
import PropertyListModel from '../models/property-list';
import template from '../templates/add.html';
import PropertyListSelectorView from './selector';
import { getIndividualLink, getName } from '../../formatter';

export default class PropertyListAddView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'property_lists';

        this.events = {
            'typeahead:select #cntr-client_id': this.selectClient,
            'change select[name="list"]': this.handleListChange,
            submit: this.handleSubmit,
        };

        this.type = options.type || '';

        this.clientListsCollection = new PropertyListCollection();
        this.myListsCollection = new PropertyListCollection();
        this.sharedListsCollection = new PropertyListCollection();
        this.publicListsCollection = new PropertyListCollection();

        // Create subviews
        this.subviews = {
            clientTypeahead: new UiTypeaheadView({
                placeholder: 'Client',
                options: TypeaheadDefault,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                formatter: getName,
                urlGenerator: getIndividualLink,
            }),
            clientLists: new PropertyListSelectorView({
                attributes: {
                    name: 'list',
                    disabled: true,
                },
                collection: this.clientListsCollection,
                prependOptions: [{ label: '', value: '' }],
                visibility: 'shared',
            }),
            myLists: new PropertyListSelectorView({
                attributes: {
                    name: 'list',
                },
                collection: this.myListsCollection,
                prependOptions: [{ label: '', value: '' }],
                visibility: 'private',
            }),
            sharedLists: new PropertyListSelectorView({
                attributes: {
                    name: 'list',
                },
                collection: this.sharedListsCollection,
                prependOptions: [{ label: '', value: '' }],
                visibility: 'shared',
            }),
            publicLists: new PropertyListSelectorView({
                attributes: {
                    name: 'list',
                },
                collection: this.publicListsCollection,
                prependOptions: [{ label: '', value: '' }],
                visibility: 'public',
            }),
        };
    }

    initialize() {
        _.bindAll(this, 'render');

        this.render();

        // Setup listeners
        this.listenTo(this.subviews.clientTypeahead, 'clear', this.clearClient);

        this.myListsCollection.fetch({
            data: {
                visibility: 'private',
            },
            reset: true,
        });

        this.sharedListsCollection.fetch({
            data: {
                visibility: 'shared',
            },
            reset: true,
        });

        this.publicListsCollection.fetch({
            data: {
                visibility: 'public',
            },
            reset: true,
        });
    }

    render() {
        console.debug('PropertyListAddView#render');

        this.el.innerHTML = template();

        // Set element for client typeahead subview and render
        this.$el
            .find('#cntr-client_id')
            .html(this.subviews.clientTypeahead.render().el);

        // Set element for client lists subview and render
        this.subviews.clientLists
            .setElement(this.$el.find('#cntr-client_lists'))
            .render();

        // Disable "add" button
        /** @todo Should be able to set desired attributes externally, and changes should trigger view to update DOM */
        this.$el
            .find('#cntr-client_lists [data-action="add"]')
            .prop('disabled', true);

        // Set element for my lists subview, and trigger fetch
        this.subviews.myLists
            .setElement(this.$el.find('#cntr-my_lists'))
            .render();

        // Set element for shared lists subview, and trigger fetch
        this.subviews.sharedLists
            .setElement(this.$el.find('#cntr-shared_lists'))
            .render();

        // Set element for public lists subview, and trigger fetch
        this.subviews.publicLists
            .setElement(this.$el.find('#cntr-public_lists'))
            .render();

        const selected = propertyRestoreSelectedService();

        // Get properties
        const query = {
            sort_key: Preferences.sort_key,
            sort_order: Preferences.sort_order,
            limit: 1000,
            id: 0 === selected.length ? -1 : selected,
        };

        let searchResultsView;
        if (this.type === 'building') {
            searchResultsView = new BuildingSearchResultsView({
                query,
                format: 'short',
                el: this.el.querySelector('#results'),
            });
        } else {
            searchResultsView = new PropertySearchResultsView({
                query,
                format: 'short',
                el: this.el.querySelector('#results'),
            });
        }

        searchResultsView.render().fetch();

        return this;
    }

    selectClient($e, datum) {
        console.debug('PropertyListAddView#selectClient');

        // Set client ID in client lists subview
        this.subviews.clientLists.addressbook_id = datum.id;

        // Fetch
        this.subviews.clientLists.collection
            .fetch({
                data: {
                    addressbook_id: datum.id,
                },
                reset: true,
            })
            .then(() => {
                // Enable list selection dropdown and "add list" button
                this.subviews.clientLists.$el
                    .find('select[name="list"]')
                    .prop('disabled', false);
                this.subviews.clientLists.$el
                    .find('[data-action="add"]')
                    .prop('disabled', false);
            });
    }

    clearClient() {
        console.debug('PropertyListAddView#clearClient');

        // Clear client ID in client lists subview
        this.subviews.clientLists.addressbook_id = null;

        // Empty and disable list selection dropdown and button
        /**
         * @todo Ideally we just set a property on the subview, which would react to the change... need React.js?
         */
        this.subviews.clientLists.$el
            .find('select[name="list"]')
            .empty()
            .prop('disabled', true);
        this.subviews.clientLists.$el
            .find('[data-action="add"]')
            .prop('disabled', true);
        this.subviews.clientLists.selected = null;
    }

    handleListChange($e) {
        console.debug('PropertyListAddView#handleListChange');

        if ($e.currentTarget.value) {
            // Disable all "list" dropdowns
            this.$el.find('select[name="list"]').prop('disabled', true);

            // Enable current "list" dropdown
            $e.currentTarget.disabled = false;
        } else {
            // Enable all "list" dropdowns
            this.$el.find('select[name="list"]').prop('disabled', false);
        }
    }

    handleSubmit($e) {
        console.debug('PropertyListAddView#handleSubmit');

        $e.preventDefault();

        const selectedItems = propertyRestoreSelectedService();

        // Get selected items
        if (_.isEmpty(selectedItems)) {
            return;
        }

        // Get chosen list
        const list = this.$el.find('select[name="list"]:not([disabled])').val();
        if (_.isEmpty(list)) {
            return;
        }

        // Create model with list ID
        const model = new PropertyListModel({
            id: list,
        });

        // Fetch model data
        model
            .fetch()
            .then((response) => {
                if (this.type === 'building') {
                    // Merge items with existing items and save
                    return model.save(
                        'buildings',
                        _.union(response.buildings, selectedItems),
                        { patch: true, wait: true },
                    );
                } else {
                    // Merge items with existing items and save
                    return model.save(
                        'properties',
                        _.union(response.properties, selectedItems),
                        { patch: true, wait: true },
                    );
                }
            })
            .then(() => {
                // Clear selections
                propertyRestoreSelectedService([]);

                // Redirect to edit list
                history.navigate('property/lists/edit/' + list, {
                    trigger: true,
                });
            });
    }
}
