module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mt-3">\n    <div class="row align-items-center">\n        <div class="col">\n            <h1 class="h2 my-0">\n                Individual: <span data-outlet="last_name"></span> <span data-outlet="first_name"></span>\n            </h1>\n            <span data-outlet="company_name"></span>\n        </div>\n\n        <div class="col-auto">\n            <button type="button" title="View changelog" class="btn btn-secondary" data-action="changelog">Changelog</button>\n            ';
 if (isDeleteAllowed) { ;
__p += '\n            <button type="button" title="Delete Person" class="btn btn-danger" data-action="delete">Delete</button>\n            ';
 } ;
__p += '\n            <!-- <p class="my-0 text-right">ID: <span data-outlet="id"></span></p> -->\n        </div>\n    </div>\n</div>\n\n<hr>\n\n<div class="alert alert-danger text-center d-none" data-outlet="deleted-alert">Client has been deleted</div>\n\n<ul class="nav nav-tabs">\n    <li class="nav-item">\n        <a class="nav-link active" href="#tab-profile" data-toggle="tab">Profile</a>\n    </li>\n    <li class="nav-item">\n        <a class="nav-link" href="#tab-activity" data-toggle="tab">Activity</a>\n    </li>\n\n    <li class="nav-item d-none" data-tab="criteria">\n        <a class="nav-link" href="#tab-criteria" data-toggle="tab">Criteria</a>\n    </li>\n    <li class="nav-item">\n        <a class="nav-link" href="#tab-alerts" data-toggle="tab">Alerts</a>\n    </li>\n    <li class="nav-item">\n        <a class="nav-link" href="#tab-inquiries" data-toggle="tab">Enquiries</a>\n    </li>\n\n    <!--<li class="nav-item">\n        <a class="nav-link" href="#tab-favorites" id="tab-favorites" data-toggle="tab">Favorites</a>\n    </li>-->\n\n    <li class="nav-item d-none" data-tab="property_lists">\n        <a class="nav-link" href="#tab-property_lists" data-toggle="tab">Property Lists</a>\n    </li>\n\n    <li class="nav-item" data-tab="deals">\n        <a class="nav-link" href="#tab-deals" data-toggle="tab">Deals</a>\n    </li>\n\n    <li class="nav-item d-none" data-tab="property">\n        <a class="nav-link" href="#tab-property" data-toggle="tab">Property</a>\n    </li>\n</ul>\n\n<div class="tab-content pt-3">\n    <div id="tab-profile" class="tab-pane show active"></div>\n    <div id="tab-activity" class="tab-pane"></div>\n    <div id="tab-criteria" class="tab-pane"></div>\n    <div id="tab-alerts" class="tab-pane"></div>\n    <div id="tab-inquiries" class="tab-pane"></div>\n    <div id="tab-property_lists" class="tab-pane"></div>\n    <div id="tab-deals" class="tab-pane"></div>\n</div>\n';

}
return __p
};
