import { View } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import Session from '@/js/app/session';
import StaffCollection from '@/js/app/staff/collections/staff';
import * as TextHelper from '@/js/app/text-helper';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import PeopleCollection from '../collections/people';
import PeopleCriteria from '../criterias/people';
import PeopleModel from '../models/people';
import template from '../templates/search.html';
import PeopleNewView from './new';
import PeopleResultsView from './results';

export default class PeopleSearchView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'people';

        this.events = {
            'submit form': this.handleSubmit,
            'click [data-action="reset"]': this.handleReset,
            'click #btn-new_person': this.newPerson,
        };

        // Create models and collections
        this.criteria = new PeopleCriteria();
        this.staffCollection = new StaffCollection();
        this.peopleCollection = new PeopleCollection();

        // Create subviews
        this.subviews = {
            assignedStaff: new UiIndividualSelectorView({
                id: 'field-assigned_staff',
                collection: this.staffCollection,
                attributes: {
                    name: 'assigned_staff_id',
                },
            }),
            peopleResults: new PeopleResultsView({
                collection: this.peopleCollection,
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'handleSubmit');

        this.render();

        // Fetch staff
        this.staffCollection.fetch({
            data: {
                active: 1,
                order: 'last_name',
            },
        });
    }

    render() {
        console.debug('PeopleSearchView#render');

        let isClient = 'any';
        switch (Session.data.division_id) {
            case 1:
                isClient = 'leasing';
                break;
            case 3:
                isClient = 'pm';
                break;
            case 2:
                isClient = 'sales';
                break;
            case 12:
                isClient = 'rh';
                break;
        }

        // Render template
        this.el.innerHTML = template({
            country: TextHelper._source.country,
            isClient,
        });

        // Attach assignedStaff subview to DOM
        this.$el
            .find('#cntr-assigned_staff')
            .html(this.subviews.assignedStaff.el);

        // Attach peopleResults subview to DOM
        this.subviews.peopleResults.setElement(
            this.el.querySelector('#results'),
        );

        // Get search criteria from sessionStorage
        this.peopleSearchCriteria = JSON.parse(
            sessionStorage.getItem('peopleSearchCriteria'),
        );

        // If search conditions exists
        if (this.peopleSearchCriteria) {
            const { assigned_staff_id } = this.peopleSearchCriteria;
            populateForm(
                this.el.querySelector('#frm-people-search'),
                this.peopleSearchCriteria,
            );
            this.subviews.assignedStaff.setSelected(assigned_staff_id);
        }

        return this;
    }

    handleSubmit($e) {
        console.debug('PeopleSearchView#handleSubmit');

        $e.preventDefault();

        // Get defaults, and merge with criteria from form
        const criteria = this.criteria.defaults();

        const form = this.el.querySelector('#frm-people-search');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        sessionStorage.setItem(
            'peopleSearchCriteria',
            JSON.stringify(criteria),
        );

        // Clear existing criteria, and set new criteria
        this.criteria.clear({ silent: true }).set(criteria);
    }

    handleReset(e) {
        e.preventDefault();

        this.el.querySelector('form').reset();

        sessionStorage.removeItem('peopleSearchCriteria');
    }

    newPerson() {
        console.debug('PeopleSearchView#newPerson');

        new PeopleNewView({
            model: new PeopleModel(),
        }).render();
    }
}
