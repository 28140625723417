import { View } from 'backbone';
import DealEditView from '../views/edit';
import DealModel from '../models/deal';
import template from '../templates/page-edit.html';

export default class DealNewPageView extends View {
    preinitialize(options) {
        this.subviews = {
            edit: new DealEditView({
                model: new DealModel({
                    type: options.type,
                }),
            }),
        };
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('DealEditPageView#render');

        this.el.innerHTML = template();

        this.$el.find('#container-edit').append(this.subviews.edit.render().el);
    }
}
