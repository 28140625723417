import { View } from 'backbone';
import template from '../templates/index.html';
import ReportInquiriesResultsView from './results';

export default class ReportInquiriesIndexView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'reports';

        this.subviews = {
            results: new ReportInquiriesResultsView(),
        };
    }

    initialize() {
        this.render();

        // Fetch model
        this.subviews.results.model.fetch();
    }

    render() {
        console.debug('ReportInquiriesIndexView#render');

        // Render template
        this.el.innerHTML = template();

        // Attach results subview
        this.subviews.results.setElement(this.el.querySelector('#results'));

        return this;
    }
}
