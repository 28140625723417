import uploader from '@/js/app/file-uploader/uploader';
import ModalView from '@/js/app/generic/modal';
import async from 'async';
import template from '../templates/modal_files.html';
import PropertyFilesItemView from './modal-files-item';

export default class ModalFilesView extends ModalView {
    preinitialize(options) {
        super.preinitialize();
        this.title = options.title || 'Files';

        this.events = {
            'click .btn-file-upload': this.openFileSelector,
            'change .file-selector': this.uploadFile,
        };

        // Create subviews
        this.subviews = {
            // Create array of file views
            fileItemViewList: [],
        };
    }

    initialize() {
        _.bindAll(this, 'addFileItem');

        // When model added to collection; add file item
        this.listenTo(this.collection, 'add', this.addFileItem);
    }

    render() {
        console.debug('ModalFilesView#render');

        super.render({
            size: 'xl',
        }).modalContent.innerHTML = template({
            title: this.title,
        });

        this.collection.each(this.addFileItem);

        return this;
    }

    addFileItem(model) {
        console.debug('ModalFilesView#addFileItem');

        // Create file item view with model
        const fileItem = new PropertyFilesItemView({
            model: model,
        });

        // Push item view to array
        this.subviews.fileItemViewList.push(fileItem);

        // Render view and append to file list
        this.$el.find('.file-list tbody').append(fileItem.render().el);
    }

    openFileSelector() {
        console.debug('ModalFilesView#openFileSelector');

        // Trigger click on file upload input
        this.$el.find('.file-selector').trigger('click');
    }

    uploadFile($e) {
        console.debug('ModalFilesView#uploadFile');

        const files = $e.currentTarget.files;
        const uploadStatusContainer = this.$el.find('.cntr-upload-status');

        // Create file upload queue with handler
        const q = async.queue((file, callback) => {
            // Upload file
            /**
             * Moving forward, file uploader could either connect to a collection, or only connect to the file upload API.
             * This would negate the need for extra parameters, like propertyId
             */
            uploader(
                file,
                {
                    method: 'PUT',
                    url: this.collection.url() + '/' + file.name,
                },
                {
                    uploadStatusContainer: uploadStatusContainer,
                },
            )
                .then((response) => {
                    // Add new file model to collection using response data
                    this.collection.add(response);
                })
                .then(callback)
                .catch(callback);
        }, 1);

        // Loop through the FileList
        for (const file of files) {
            // Add file to queue
            q.push(file);
        }

        // Clear file selector
        $e.currentTarget.value = '';
    }
}
