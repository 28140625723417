import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasets/company';
import BloodhoundIndividualDataset from '@/js/app/bloodhound/datasets/individual';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ChangelogsView from '@/js/app/changelog/views/changelogs';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';
import BuildingModel from '@/js/app/property/models/building';
import UnitModel from '@/js/app/property/models/unit';
import propertyParseIdService from '@/js/app/property/services/parse-id';
import Session from '@/js/app/session';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasources/company';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import TypeaheadDefault, {
    TypeaheadCompanyDefaults,
} from '@/js/app/typeahead/defaults';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import populateForm from '@/js/libs/populate-form';
import { getFieldValue } from '@/js/libs/view-utils';
import { history, View } from 'backbone';
import DealFileCollection from '../collections/files';
import RevenueCollection from '../collections/revenue';
import template from '../templates/edit.html';
import ModalFilesView from './modal-files';
import DealRevenueView from './revenue';
import { getCompanyLink, getIndividualLink } from '../../formatter';

function getIndividualOrCompanyLink(datum) {
    switch (datum._type) {
        case 'individual':
            return getIndividualLink(datum);
        case 'company':
            return getCompanyLink(datum);
        case '':
            return '';
    }
}

export default class DealEditView extends View {
    #mode = 'read';

    preinitialize(options) {
        this.tagName = 'section';

        this.events = {
            'change #field-property_id': this.populatePropertyData,
            'change [data-lock-row-on-paid]': this.toggleRowLock,
            'click button[data-action="copy"]': this.copy,
            'click button[data-action="delete"]': this.delete,
            'click button[data-action="generate-pdf"]': this.generatePdf,
            'click button[data-action="changelog"]': this.changelog,
            'typeahead:select #cntr-貸主_本人 input[type="text"]':
                this.selectSeller,
            'click #cntr-貸主_本人 [data-action="delete"]': this.clearSeller,
            'typeahead:select #cntr-貸主_本人2 input[type="text"]':
                this.selectSeller2,
            'click #cntr-貸主_本人2 [data-action="delete"]': this.clearSeller2,
            'typeahead:select #cntr-貸主_代理人 input[type="text"]':
                this.selectSellerProxy,
            'click #cntr-貸主_代理人 [data-action="delete"]':
                this.clearSellerProxy,
            'typeahead:select #cntr-借主_本人 input[type="text"]':
                this.selectBuyer,
            'click #cntr-借主_本人 [data-action="delete"]': this.clearBuyer,
            'typeahead:select #cntr-借主_本人2 input[type="text"]':
                this.selectBuyer2,
            'click #cntr-借主_本人2 [data-action="delete"]': this.clearBuyer2,
            'typeahead:select #cntr-借主_代理人 input[type="text"]':
                this.selectBuyerProxy,
            'click #cntr-借主_代理人 [data-action="delete"]':
                this.clearBuyerProxy,
            'typeahead:select #cntr-借主_入居者 input[type="text"]':
                this.selectTenant,
            'click #cntr-借主_入居者 [data-action="delete"]': this.clearTenant,
            'typeahead:select #cntr-takkengyosha_shogo input[type="text"]':
                this.selectRealEstateCompany,
            'click #cntr-takkengyosha_shogo [data-action="delete"]':
                this.clearRealEstateCompany,
            'typeahead:select #cntr-kanriitakusaki_senyubu input[type="text"]':
                this.selectManagementCompanyProprietaryArea,
            'click #cntr-kanriitakusaki_senyubu [data-action="delete"]':
                this.clearManagementCompanyProprietaryArea,
            'typeahead:select #cntr-kanriitakusaki_kyoyobu input[type="text"]':
                this.selectManagementCompanyCommonArea,
            'click #cntr-kanriitakusaki_kyoyobu [data-action="delete"]':
                this.clearManagementCompanyCommonArea,
            'change input[name],select[name],textarea[name]': this.updateModel,
            'click button[data-action="files"]': this.handleFilesClick,
            'click button[data-action="edit"]': this.handleEditClick,
            'click button[data-action="cancel"]': this.handleCancelClick,
            'click button[data-action="save"]': this.handleSaveClick,
        };

        // Create subview containers
        this.subviews = {
            revenue: new DealRevenueView({
                collection: new RevenueCollection(),
            }),
            filesModal: new ModalFilesView({
                title: 'Files',
                collection: new DealFileCollection(null, {
                    deal_id: options.model.id,
                }),
            }),
            seller: new UiTypeaheadView({
                required: true,
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            seller2: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            sellerProxy: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            buyer: new UiTypeaheadView({
                required: true,
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            buyer2: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            buyerProxy: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            tenant: new UiTypeaheadView({
                required: true,
                options: TypeaheadDefault,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualLink,
            }),
            takkengyosha_shogo: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                urlGenerator: getCompanyLink,
            }),
            kanriitakusaki_senyubu: new UiTypeaheadView({
                required: true,
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                urlGenerator: getCompanyLink,
            }),
            kanriitakusaki_kyoyobu: new UiTypeaheadView({
                required: true,
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                urlGenerator: getCompanyLink,
            }),
        };
    }

    initialize() {
        _.bindAll(
            this,
            'render',
            'resetCustomValidation',
            'toggleRowLock',
            'changelog',
        );

        // When division_approved changes: toggle mutability
        this.listenTo(
            this.model,
            'change:division_approved',
            this.toggleMutability,
        );

        // When accounting_approved changes: toggle division approval mutability
        this.listenTo(
            this.model,
            'change:accounting_approved',
            this.toggleDivisionApprovalMutability,
        );

        // When division_approved changes: toggle accounting approval mutability
        this.listenTo(
            this.model,
            'change:division_approved',
            this.toggleAccountingApprovalMutability,
        );

        // When v changes: toggle deduction system
        this.listenTo(this.model, 'change:v', this.toggleDeductionSystem);

        // When 建物の種類 changes: toggle mansion section
        this.listenTo(
            this.model,
            'change:建物の種類',
            this.toggleMansionSection,
        );

        // When building ID or unit ID changes: update link
        this.listenTo(
            this.model,
            'change:building_id change:unit_id',
            this.updatePropertyURL,
        );

        // Update outlets
        this.listenTo(this.model, 'change', this.updateOutlets);
    }

    fetch() {
        if (this.model.id) {
            // Trigger fetch, and call "render" on success
            this.model
                .fetch({
                    silent: true, // Mark: I know this is not recommended, but we need to get data into this model without triggering any "change" events
                })
                .then(this.render);

            // Trigger fetch on revenue list
            this.subviews.revenue.collection.fetch({
                data: {
                    deal_id: this.model.id,
                    include: ['division', 'staff'],
                },
            });
        }
    }

    render() {
        console.debug('DealEditView#render');

        this.el.innerHTML = template({
            id: this.model.get('custom_id')
                ? this.model.get('custom_id')
                : this.model.id,
            version: this.model.get('v'),
            dealType: this.model.get('type'),
            isDeleteAllowed: Session.isAllowed('phnx:deals:d'),
            isAccountingAllowed: Session.isAllowed('phnx:accounting'),
        });

        // Render typeahead subviews
        this.$el.find('#cntr-貸主_本人').html(this.subviews.seller.render().el);
        this.$el
            .find('#cntr-貸主_本人2')
            .html(this.subviews.seller2.render().el);
        this.$el
            .find('#cntr-貸主_代理人')
            .html(this.subviews.sellerProxy.render().el);
        this.$el.find('#cntr-借主_本人').html(this.subviews.buyer.render().el);
        this.$el
            .find('#cntr-借主_本人2')
            .html(this.subviews.buyer2.render().el);
        this.$el
            .find('#cntr-借主_代理人')
            .html(this.subviews.buyerProxy.render().el);
        this.$el
            .find('#cntr-借主_入居者')
            .html(this.subviews.tenant.render().el);
        this.$el
            .find('#cntr-takkengyosha_shogo')
            .html(this.subviews.takkengyosha_shogo.render().el);
        this.$el
            .find('#cntr-kanriitakusaki_senyubu')
            .html(this.subviews.kanriitakusaki_senyubu.render().el);
        this.$el
            .find('#cntr-kanriitakusaki_kyoyobu')
            .html(this.subviews.kanriitakusaki_kyoyobu.render().el);

        this.$el.find('#container-revenue').append(this.subviews.revenue.el);

        populateForm(this.el, this.model.toJSON());

        // Initialize typeahead values and links
        this.subviews.seller.setValue(this.model.get('貸主_本人'));
        this.subviews.seller.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('貸主_本人_id'),
                _type: this.model.get('貸主_本人_type'),
            }),
        );
        this.subviews.seller2.setValue(this.model.get('貸主_本人2'));
        this.subviews.seller2.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('貸主_本人2_id'),
                _type: this.model.get('貸主_本人2_type'),
            }),
        );
        this.subviews.sellerProxy.setValue(this.model.get('貸主_代理人'));
        this.subviews.sellerProxy.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('貸主_代理人_id'),
                _type: this.model.get('貸主_代理人_type'),
            }),
        );
        this.subviews.buyer.setValue(this.model.get('借主_本人'));
        this.subviews.buyer.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('借主_本人_id'),
                _type: this.model.get('借主_本人_type'),
            }),
        );
        this.subviews.buyer2.setValue(this.model.get('借主_本人2'));
        this.subviews.buyer2.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('借主_本人2_id'),
                _type: this.model.get('借主_本人2_type'),
            }),
        );
        this.subviews.buyerProxy.setValue(this.model.get('借主_代理人'));
        this.subviews.buyerProxy.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('借主_代理人_id'),
                _type: this.model.get('借主_代理人_type'),
            }),
        );
        this.subviews.tenant.setValue(this.model.get('借主_入居者'));
        this.subviews.tenant.setLinkURL(
            getIndividualLink({ id: this.model.get('借主_入居者_id') }),
        );
        this.subviews.takkengyosha_shogo.setValue(
            this.model.get('takkengyosha_shogo'),
        );
        this.subviews.takkengyosha_shogo.setLinkURL(
            getCompanyLink({ id: this.model.get('takkengyosha_shogo_id') }),
        );
        this.subviews.kanriitakusaki_senyubu.setValue(
            this.model.get('kanriitakusaki_senyubu'),
        );
        this.subviews.kanriitakusaki_senyubu.setLinkURL(
            getCompanyLink({ id: this.model.get('kanriitakusaki_senyubu_id') }),
        );
        this.subviews.kanriitakusaki_kyoyobu.setValue(
            this.model.get('kanriitakusaki_kyoyobu'),
        );
        this.subviews.kanriitakusaki_kyoyobu.setLinkURL(
            getCompanyLink({ id: this.model.get('kanriitakusaki_kyoyobu_id') }),
        );

        this.updatePropertyURL(this.model);

        // Populate property ID (this is needed because field does not have a "name" attribute)
        this.$el.find(`#field-property_id`).val(this.model.get('property_id'));

        /* when a field changes and is no longer empty, set these other fields as required, otherwise clear requirement */
        const that = this;
        const bindRequireFields = function (field) {
            field =
                'undefined' !== typeof field && !field.bubbles ? field : this;

            const this_value = field.value;
            const this_required_fields = field.dataset.requireFields.split(',');

            if (this_required_fields.length) {
                for (let i = this_required_fields.length - 1; i >= 0; i--) {
                    that.el.querySelector(
                        '[name="' + this_required_fields[i] + '"]',
                    ).required = this_value.length > 0;
                }
            }
        };
        this.$el.find('[data-require-fields]').on('change', bindRequireFields);
        this.$el.find('[data-require-fields]').each(function (i, field) {
            bindRequireFields(field);
        });

        // Toggle mutability
        this.toggleMutability(this.model);

        // Toggle division approval mutability
        this.toggleDivisionApprovalMutability(this.model);

        // Toggle accounting approval mutability
        this.toggleAccountingApprovalMutability(this.model);

        // Toggle deductions
        this.toggleDeductionSystem(this.model);

        // Toggle mansion section
        this.toggleMansionSection(this.model);

        // Add placeholder to typeahead fields
        this.$el.find('.tt-input').prop('placeholder', '\uf002');

        this.#initializeReadEditMode();

        return this;
    }

    updatePropertyURL(model) {
        console.debug('DealEditView#updatePropertyURL');

        const propertyLink = this.el.querySelector(
            '[data-outlet="property_link"]',
        );
        const buildingId = model.get('building_id');

        let newUrl = '';
        if (buildingId) {
            // Build new URL
            newUrl = '#property/edit/' + model.get('building_id') + '.0';

            const unitId = model.get('unit_id');
            if (unitId) {
                newUrl += '/' + unitId;
            }

            // Show property link
            propertyLink.classList.remove('d-none');
        } else {
            // Hide property link
            propertyLink.classList.add('d-none');
        }

        // Set new URL on property link
        propertyLink.href = newUrl;
    }

    updateModel($e) {
        console.debug('DealEditView#updateModel');

        const field = $e.currentTarget;

        // If no name, return
        if (field.name === '') {
            return;
        }

        const value = getFieldValue(this.el, field);

        this.model.set(field.name, value);
    }

    updateOutlets() {
        console.debug('DealEditView#updateOutlets');

        const changes = this.model.changedAttributes();

        console.debug('DealEditView#updateOutlets: Changes', changes);

        // Populate form fields
        populateForm(this.el, changes);

        // Reset typeaheads
        if (_.has(changes, '貸主_本人')) {
            this.subviews.seller.setValue(this.model.get('貸主_本人'));
        }
        if (
            _.has(changes, '貸主_本人_id') ||
            _.has(changes, '貸主_本人_type')
        ) {
            this.subviews.seller.setLinkURL(
                getIndividualOrCompanyLink({
                    id: this.model.get('貸主_本人_id'),
                    _type: this.model.get('貸主_本人_type'),
                }),
            );
        }
        if (_.has(changes, '貸主_本人2')) {
            this.subviews.seller2.setValue(this.model.get('貸主_本人2'));
        }
        if (
            _.has(changes, '貸主_本人2_id') ||
            _.has(changes, '貸主_本人2_type')
        ) {
            this.subviews.seller2.setLinkURL(
                getIndividualOrCompanyLink({
                    id: this.model.get('貸主_本人2_id'),
                    _type: this.model.get('貸主_本人2_type'),
                }),
            );
        }
        if (_.has(changes, '貸主_代理人')) {
            this.subviews.sellerProxy.setValue(this.model.get('貸主_代理人'));
        }
        if (
            _.has(changes, '貸主_代理人_id') ||
            _.has(changes, '貸主_代理人_type')
        ) {
            this.subviews.sellerProxy.setLinkURL(
                getIndividualOrCompanyLink({
                    id: this.model.get('貸主_代理人_id'),
                    _type: this.model.get('貸主_代理人_type'),
                }),
            );
        }
        if (_.has(changes, '借主_本人')) {
            this.subviews.buyer.setValue(this.model.get('借主_本人'));
        }
        if (
            _.has(changes, '借主_本人_id') ||
            _.has(changes, '借主_本人_type')
        ) {
            this.subviews.buyer.setLinkURL(
                getIndividualOrCompanyLink({
                    id: this.model.get('借主_本人_id'),
                    _type: this.model.get('借主_本人_type'),
                }),
            );
        }
        if (_.has(changes, '借主_本人2')) {
            this.subviews.buyer2.setValue(this.model.get('借主_本人2'));
        }
        if (
            _.has(changes, '借主_本人2_id') ||
            _.has(changes, '借主_本人2_type')
        ) {
            this.subviews.buyer2.setLinkURL(
                getIndividualOrCompanyLink({
                    id: this.model.get('借主_本人2_id'),
                    _type: this.model.get('借主_本人2_type'),
                }),
            );
        }
        if (_.has(changes, '借主_代理人')) {
            this.subviews.buyerProxy.setValue(this.model.get('借主_代理人'));
        }
        if (
            _.has(changes, '借主_代理人_id') ||
            _.has(changes, '借主_代理人_type')
        ) {
            this.subviews.buyerProxy.setLinkURL(
                getIndividualOrCompanyLink({
                    id: this.model.get('借主_代理人_id'),
                    _type: this.model.get('借主_代理人_type'),
                }),
            );
        }
        if (_.has(changes, '借主_入居者')) {
            this.subviews.tenant.setValue(this.model.get('借主_入居者'));
        }
        if (_.has(changes, '借主_入居者_id')) {
            this.subviews.tenant.setLinkURL(
                getIndividualLink({ id: this.model.get('借主_入居者_id') }),
            );
        }
        if (_.has(changes, 'takkengyosha_shogo')) {
            this.subviews.takkengyosha_shogo.setValue(
                this.model.get('takkengyosha_shogo'),
            );
        }
        if (_.has(changes, 'takkengyosha_shogo_id')) {
            this.subviews.takkengyosha_shogo.setLinkURL(
                getCompanyLink({ id: this.model.get('takkengyosha_shogo_id') }),
            );
        }
        if (_.has(changes, 'kanriitakusaki_senyubu')) {
            this.subviews.kanriitakusaki_senyubu.setValue(
                this.model.get('kanriitakusaki_senyubu'),
            );
        }
        if (_.has(changes, 'kanriitakusaki_senyubu_id')) {
            this.subviews.kanriitakusaki_senyubu.setLinkURL(
                getCompanyLink({
                    id: this.model.get('kanriitakusaki_senyubu_id'),
                }),
            );
        }
        if (_.has(changes, 'kanriitakusaki_kyoyobu')) {
            this.subviews.kanriitakusaki_kyoyobu.setValue(
                this.model.get('kanriitakusaki_kyoyobu'),
            );
        }
        if (_.has(changes, 'kanriitakusaki_kyoyobu_id')) {
            this.subviews.kanriitakusaki_kyoyobu.setLinkURL(
                getCompanyLink({
                    id: this.model.get('kanriitakusaki_kyoyobu_id'),
                }),
            );
        }
    }

    selectSeller($e, datum) {
        console.debug('DealEditView#selectSeller');

        this.model.set({
            貸主_本人_id: datum.id,
            貸主_本人_type: datum._type, // This is set during transform() in Bloodhound
            貸主_本人: $e.currentTarget.value,
            貸主_本人_住所:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            貸主_本人_TEL: datum.telephone || '',
        });
    }

    clearSeller($e) {
        console.debug('DealEditView#clearSeller');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            貸主_本人_id: null,
            貸主_本人_type: null,
            貸主_本人: null,
            貸主_本人_住所: null,
            貸主_本人_TEL: null,
        });
    }

    selectSeller2($e, datum) {
        console.debug('DealEditView#selectSeller2');

        this.model.set({
            貸主_本人2_id: datum.id,
            貸主_本人2_type: datum._type, // This is set during transform() in Bloodhound
            貸主_本人2: $e.currentTarget.value,
            貸主_本人2_住所:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            貸主_本人2_TEL: datum.telephone || '',
        });
    }

    clearSeller2($e) {
        console.debug('DealEditView#clearSeller2');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            貸主_本人2_id: null,
            貸主_本人2_type: null,
            貸主_本人2: null,
            貸主_本人2_住所: null,
            貸主_本人2_TEL: null,
        });
    }

    selectSellerProxy($e, datum) {
        console.debug('DealEditView#selectSellerProxy');

        this.model.set({
            貸主_代理人_id: datum.id,
            貸主_代理人_type: datum._type, // This is set during transform() in Bloodhound
            貸主_代理人: $e.currentTarget.value,
            貸主_代理人_住所:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            貸主_代理人_TEL: datum.telephone || '',
        });
    }

    clearSellerProxy($e) {
        console.debug('DealEditView#clearSellerProxy');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            貸主_代理人_id: null,
            貸主_代理人_type: null,
            貸主_代理人: null,
            貸主_代理人_住所: null,
            貸主_代理人_TEL: null,
        });
    }

    selectBuyer($e, datum) {
        console.debug('DealEditView#selectBuyer');

        this.model.set({
            借主_本人_id: datum.id,
            借主_本人_type: datum._type, // This is set during transform() in Bloodhound
            借主_本人: $e.currentTarget.value,
            借主_本人_住所:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            借主_本人_TEL: datum.telephone || '',
        });
    }

    clearBuyer($e) {
        console.debug('DealEditView#clearBuyer');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            借主_本人_id: null,
            借主_本人_type: null,
            借主_本人: null,
            借主_本人_住所: null,
            借主_本人_TEL: null,
        });
    }

    selectBuyer2($e, datum) {
        console.debug('DealEditView#selectBuyer2');

        this.model.set({
            借主_本人2_id: datum.id,
            借主_本人2_type: datum._type, // This is set during transform() in Bloodhound
            借主_本人2: $e.currentTarget.value,
            借主_本人2_住所:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            借主_本人2_TEL: datum.telephone || '',
        });
    }

    clearBuyer2($e) {
        console.debug('DealEditView#clearBuyer2');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            借主_本人2_id: null,
            借主_本人2_type: null,
            借主_本人2: null,
            借主_本人2_住所: null,
            借主_本人2_TEL: null,
        });
    }

    selectBuyerProxy($e, datum) {
        console.debug('DealEditView#selectBuyerProxy');

        this.model.set({
            借主_代理人_id: datum.id,
            借主_代理人_type: datum._type, // This is set during transform() in Bloodhound
            借主_代理人: $e.currentTarget.value,
            借主_代理人_住所:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            借主_代理人_TEL: datum.telephone || '',
        });
    }

    clearBuyerProxy($e) {
        console.debug('DealEditView#clearBuyerProxy');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            借主_代理人_id: null,
            借主_代理人_type: null,
            借主_代理人: null,
            借主_代理人_住所: null,
            借主_代理人_TEL: null,
        });
    }

    selectTenant($e, datum) {
        console.debug('DealEditView#selectTenant');

        this.model.set({
            借主_入居者_id: datum.id,
            借主_入居者: $e.currentTarget.value,
            借主_入居者_住所:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            借主_入居者_TEL: datum.telephone || '',
        });
    }

    clearTenant($e) {
        console.debug('DealEditView#clearTenant');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            借主_入居者_id: null,
            借主_入居者: null,
            借主_入居者_住所: null,
            借主_入居者_TEL: null,
        });
    }

    selectRealEstateCompany($e, datum) {
        console.debug('DealEditView#selectRealEstateCompany');

        this.model.set({
            takkengyosha_shogo_id: datum.id,
            takkengyosha_shogo: $e.currentTarget.value,
            takkengyosha_shozaichi:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            takkengyosha_tel: datum.telephone || '',
        });
    }

    clearRealEstateCompany($e) {
        console.debug('DealEditView#clearRealEstateCompany');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            takkengyosha_shogo_id: null,
            takkengyosha_shogo: null,
            takkengyosha_shozaichi: null,
            takkengyosha_tel: null,
        });
    }

    selectManagementCompanyProprietaryArea($e, datum) {
        console.debug('DealEditView#selectManagementCompanyProprietaryArea');

        this.model.set({
            kanriitakusaki_senyubu_id: datum.id,
            kanriitakusaki_senyubu: $e.currentTarget.value,
            kanriitakusaki_senyubu_jusho:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            kanriitakusaki_senyubu_tel: datum.telephone || '',
        });
    }

    clearManagementCompanyProprietaryArea($e) {
        console.debug('DealEditView#clearManagementCompanyProprietaryArea');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            kanriitakusaki_senyubu_id: null,
            kanriitakusaki_senyubu: null,
            kanriitakusaki_senyubu_jusho: null,
            kanriitakusaki_senyubu_tel: null,
        });
    }

    selectManagementCompanyCommonArea($e, datum) {
        console.debug('DealEditView#selectManagementCompanyCommonArea');

        this.model.set({
            kanriitakusaki_kyoyobu_id: datum.id,
            kanriitakusaki_kyoyobu: $e.currentTarget.value,
            kanriitakusaki_kyoyobu_jusho:
                (datum.postcode || '') + ' ' + (datum.address_string || ''),
            kanriitakusaki_kyoyobu_tel: datum.telephone || '',
        });
    }

    clearManagementCompanyCommonArea($e) {
        console.debug('DealEditView#clearManagementCompanyCommonArea');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        this.model.set({
            kanriitakusaki_kyoyobu_id: null,
            kanriitakusaki_kyoyobu: null,
            kanriitakusaki_kyoyobu_jusho: null,
            kanriitakusaki_kyoyobu_tel: null,
        });
    }

    populatePropertyData($e) {
        console.debug('DealEditView#populatePropertyData');

        $e.preventDefault();

        const propertyId = propertyParseIdService($e.currentTarget.value);

        // If no property ID, return
        if (!propertyId) {
            return;
        }

        // Create appropriate model, based on structure_type
        let model;
        const includes = ['location'];
        if (
            propertyId.structure_type === 'B' ||
            propertyId.structure_type === 'L'
        ) {
            model = new BuildingModel({
                id: propertyId.id,
            });
        } else if (
            propertyId.structure_type === 'A' ||
            propertyId.structure_type === 'H'
        ) {
            model = new UnitModel({
                id: propertyId.id,
            });

            // Include structure
            includes.push('structure');
        }

        const all_fields = this.$el.find(
            'input[data-populate-by="property_id"]',
        );
        const structure_name_field = this.$el.find('input[name="物件名"]');
        const structure_type_field = this.$el.find('input[name="建物の種類"]');
        const address_field = this.$el.find(
            'input[name="物件所在地_部屋番号"]',
        );
        const room_no_field = this.$el.find('input[name="物件名_部屋番号"]');
        const rent_field = this.$el.find('input[name="賃貸条件_賃料_月額"]');
        const deposit_field = this.$el.find('input[name="賃貸条件_敷金"]');
        const completion_year_field = this.$el.find(
            'input[name="建物_建築年月日"]',
        );
        const size_field = this.$el.find('input[name="建物_床面積_合計"]');
        const total_floors_field = this.$el.find(
            'input[name="建物_階数_地上"]',
        );
        const total_floors_basement_field = this.$el.find(
            'input[name="建物_階数_地下"]',
        );

        const madori_field = this.$el.find('input[name="建物_間取り"]');

        structure_name_field.removeClass('error').empty();
        all_fields
            .not(
                'input[name="賃貸条件_賃料_月額"], input[name="賃貸条件_敷金"]',
            )
            .val('');

        model
            .fetch({
                data: {
                    include: includes,
                },
            })
            .then((p) => {
                const data = {
                    property_id: $e.currentTarget.value,
                };

                // Add building and unit ID based on structure type
                if (
                    p.structure_type === 'building' ||
                    p.structure_type === 'land'
                ) {
                    data.building_id = p.id;
                } else if (
                    p.structure_type === 'unit' ||
                    p.structure_type === 'house'
                ) {
                    data.building_id = p.building_id;
                    data.unit_id = p.id;
                }

                // Structure type
                structure_type_field
                    .filter('[data-structure-type="' + p.structure_type + '"]')
                    .prop('checked', true);
                data[structure_type_field.prop('name')] = structure_type_field
                    .filter(':checked')
                    .val();

                if (
                    p.structure_type === 'building' ||
                    p.structure_type === 'land'
                ) {
                    // Structure name
                    structure_name_field.val(p.structure_name_en || '');
                    data[structure_name_field.prop('name')] =
                        p.structure_name_en || '';

                    // Address
                    address_field.val(
                        p.location.prefecture +
                            p.location.city +
                            p.location.neighborhood +
                            p.location.address,
                    );
                    data[address_field.prop('name')] =
                        p.location.prefecture +
                        p.location.city +
                        p.location.neighborhood +
                        p.location.address;

                    // Total floors
                    if (total_floors_field.length > 0) {
                        total_floors_field.val(p.total_floors);
                        data[total_floors_field.prop('name')] = p.total_floors;
                    }
                    if (total_floors_basement_field.length > 0) {
                        total_floors_basement_field.val(
                            p.total_floors_basement,
                        );
                        data[total_floors_basement_field.prop('name')] =
                            p.total_floors_basement;
                    }
                } else if (
                    p.structure_type === 'unit' ||
                    p.structure_type === 'house'
                ) {
                    // Structure name
                    structure_name_field.val(
                        p.structure.structure_name_en || '',
                    );
                    data[structure_name_field.prop('name')] =
                        p.structure.structure_name_en || '';

                    // Address
                    address_field.val(
                        p.structure.location.prefecture +
                            p.structure.location.city +
                            p.structure.location.neighborhood +
                            p.structure.location.address,
                    );
                    data[address_field.prop('name')] =
                        p.structure.location.prefecture +
                        p.structure.location.city +
                        p.structure.location.neighborhood +
                        p.structure.location.address;

                    // Total floors
                    if (total_floors_field.length > 0) {
                        total_floors_field.val(p.structure.total_floors);
                        data[total_floors_field.prop('name')] =
                            p.structure.total_floors;
                    }
                    if (total_floors_basement_field.length > 0) {
                        total_floors_basement_field.val(
                            p.structure.total_floors_basement,
                        );
                        data[total_floors_basement_field.prop('name')] =
                            p.structure.total_floors_basement;
                    }

                    // Room number
                    room_no_field.val(p.room_no);
                    data[room_no_field.prop('name')] = p.room_no;

                    // Madori
                    madori_field.val(
                        `${p.bedroom_no} ${p.floorplan_type || 'LDK'}`,
                    );
                    data[madori_field.prop('name')] =
                        `${p.bedroom_no} ${p.floorplan_type || 'LDK'}`;
                }

                // Rent
                if (rent_field.length && 0 == rent_field.val().length) {
                    rent_field.val(p.rent);
                    data[rent_field.prop('name')] = p.rent;
                }
                // Deposit
                if (deposit_field.val() && 0 == deposit_field.val().length) {
                    deposit_field.val(p.deposit_by_months * p.rent);
                    data[deposit_field.prop('name')] =
                        p.deposit_by_months * p.rent;
                }
                // Year built
                if (
                    completion_year_field.val() &&
                    0 == completion_year_field.val().length
                ) {
                    completion_year_field.val(p.completion_year);
                    data[completion_year_field.prop('name')] =
                        p.completion_year;
                }
                // Structure unit size
                if (size_field.val() && 0 == size_field.val().length) {
                    size_field.val(p.size);
                    data[size_field.prop('name')] = p.size;
                }

                // Bulk update model
                this.model.set(data);
            });
    }

    switchReadMode() {
        this.#mode = 'read';

        this.#initializeReadEditMode();

        // Remove was-validated class from form
        const form = this.el.querySelector('form');
        form.classList.remove('was-validated');

        this.#disablePromptBeforeUnload();
    }

    switchEditMode() {
        console.debug('DealEditView#switchEditMode');

        // Snapshot model
        this.model.snapshot();

        this.#mode = 'edit';

        this.#initializeReadEditMode();

        this.#enablePromptBeforeUnload();
    }

    promptBeforeUnload(e) {
        // Prevent default (modern browsers)
        e.preventDefault();

        // Set return value (legacy browsers)
        e.returnValue = true;
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            // Disable fields in view
            this.el
                .querySelectorAll('input,select,textarea')
                .forEach((el) => (el.disabled = true));

            // Show edit button
            this.el
                .querySelector('[data-action="edit"]')
                .classList.remove('d-none');

            // Hide cancel and save buttons
            this.el
                .querySelectorAll('[data-action="cancel"],[data-action="save"]')
                .forEach((el) => el.classList.add('d-none'));

            // Switch subviews into read mode
            this.subviews.seller.switchReadMode();
            this.subviews.seller2.switchReadMode();
            this.subviews.sellerProxy.switchReadMode();
            this.subviews.buyer.switchReadMode();
            this.subviews.buyer2.switchReadMode();
            this.subviews.buyerProxy.switchReadMode();
            this.subviews.tenant.switchReadMode();
            this.subviews.takkengyosha_shogo.switchReadMode();
            this.subviews.kanriitakusaki_senyubu.switchReadMode();
            this.subviews.kanriitakusaki_kyoyobu.switchReadMode();
        } else if (this.#mode === 'edit') {
            // Enable fields in view
            this.el
                .querySelectorAll('input,select,textarea')
                .forEach((el) => (el.disabled = false));

            // Hide edit button
            this.el
                .querySelector('[data-action="edit"]')
                .classList.add('d-none');

            // Hide cancel and save buttons
            this.el
                .querySelectorAll('[data-action="cancel"],[data-action="save"]')
                .forEach((el) => el.classList.remove('d-none'));

            // Switch subviews into edit mode
            this.subviews.seller.switchEditMode();
            this.subviews.seller2.switchEditMode();
            this.subviews.sellerProxy.switchEditMode();
            this.subviews.buyer.switchEditMode();
            this.subviews.buyer2.switchEditMode();
            this.subviews.buyerProxy.switchEditMode();
            this.subviews.tenant.switchEditMode();
            this.subviews.takkengyosha_shogo.switchEditMode();
            this.subviews.kanriitakusaki_senyubu.switchEditMode();
            this.subviews.kanriitakusaki_kyoyobu.switchEditMode();
        }
    }

    #disablePromptBeforeUnload() {
        // Remove prompt before unload
        window.removeEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload--;
    }

    #enablePromptBeforeUnload() {
        // Add prompt before unload
        window.addEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload++;
    }

    #showValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.remove('d-none');
    }

    #hideFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.add('d-none');
    }

    toggleRowLock(e) {
        console.debug('DealEditView#toggleRowLock');

        const checkbox = e.target ? $(e.target) : $(e);
        const rowScope = checkbox.closest('div.row[data-lock-row]');

        if (this.model.get('division_approved') || checkbox.prop('checked')) {
            // Disable all fields in row
            const inputs = rowScope.find(
                'select, input:not([data-lock-row-on-paid]):not([readonly])',
            );
            inputs.prop('disabled', true);

            // Hide typeahead clear
            //
        } else {
            // Enable regular fields in row
            const inputs = rowScope.find(
                'select, input:not([data-lock-row-on-paid]):not([readonly]):not([data-typeahead])',
            );
            inputs.prop('disabled', false);

            // Unlock typeahead fields in row (where appropriate)
            const typeaheadInputs = rowScope.find('input[data-typeahead]');
            typeaheadInputs.each((index, element) => {
                // If no value, enable typeahead
                if (element.value === '') {
                    element.disabled = false;
                }
            });

            // Show typeahead clear
            //
        }
    }

    toggleMutability(model) {
        console.debug('DealEditView#toggleMutability');

        // If division_approved; lock daicho
        if (model.get('division_approved')) {
            // Disable fields
            const inputs = this.$el.find(
                'select, textarea:not([data-backoffice-use]), input:not([data-lock-row-on-paid]):not([readonly]):not([data-backoffice-use])',
            );
            inputs.prop('disabled', true);
        }
        // Else; unlock daicho
        else {
            // Enable regular fields
            this.$el
                .find(
                    'select, textarea:not([data-backoffice-use]), input:not([data-lock-row-on-paid]):not([readonly]):not([data-backoffice-use]):not([data-typeahead])',
                )
                .prop('disabled', false);

            // Enable typeahead fields (where appropriate)
            const typeaheadInputs = this.el.querySelectorAll(
                'input[data-typeahead]',
            );
            typeaheadInputs.forEach((element) => {
                // If no value, enable typeahead
                if (element.value === '') {
                    element.disabled = false;
                }
            });

            // Re-lock paid rows as necessary
            _.each(
                this.$el.find('[data-lock-row-on-paid]'),
                this.toggleRowLock,
            );
        }
    }

    toggleDivisionApprovalMutability(model) {
        console.debug('DealEditView#toggleDivisionApprovalMutability');

        // Disable division_approved if not allowed OR accounting_approved (enable division_approved if allowed AND not accounting_approved)
        this.el.querySelector('[name="division_approved"]').disabled =
            !Session.isAllowed('phnx:deals.div_approve:u') ||
            !!model.get('accounting_approved');
    }

    toggleAccountingApprovalMutability(model) {
        console.debug('DealEditView#toggleAccountingApprovalMutability');

        // Disable accounting_approved, unless user has "phnx:deals.acct_approve:u" permission, and division approved
        this.el.querySelector('[name="accounting_approved"]').disabled = !(
            Session.isAllowed('phnx:deals.acct_approve:u') &&
            !!model.get('division_approved')
        );
    }

    toggleDeductionSystem(model) {
        console.debug('DealEditView#toggleDeductionSystem');

        if (model.get('v') === 1) {
            // Show web deduction UI
            this.$el.find('[data-deduction-web]').removeClass('d-none');

            // Hide agent deduction UI
            this.$el.find('[data-deduction-agent]').addClass('d-none');
        } else if (model.get('v') === 2) {
            // Show agent deduction UI
            this.$el.find('[data-deduction-agent]').removeClass('d-none');

            // Hide web deduction UI
            this.$el.find('[data-deduction-web]').addClass('d-none');
        } else {
            // Hide agent deduction UI
            this.$el.find('[data-deduction-agent]').addClass('d-none');

            // Hide web deduction UI
            this.$el.find('[data-deduction-web]').addClass('d-none');
        }
    }

    toggleMansionSection(model) {
        console.debug('DealEditView#toggleMansionSection');

        // If building type is mansion
        if (model.get('建物の種類') === 'マンション') {
            // Show section
            this.$el.find('#section-mansion').show();
        } else {
            // Hide section
            this.$el.find('#section-mansion').hide();
        }
    }

    resetCustomValidation(fieldEl) {
        console.debug('DealEditView#resetValidation');

        // Reset invalid feedback slot
        const errorFeedback =
            fieldEl.parentElement.querySelector('.invalid-feedback');
        if (errorFeedback) {
            errorFeedback.innerText = '';
        }

        // Reset custom validity
        fieldEl.setCustomValidity('');
    }

    save() {
        console.debug('DealEditView#save');

        const form = this.el.querySelector('form');

        // Add was-validated class to form
        form.classList.add('was-validated');

        // Get invalid fields
        const invalidFields = this.el.querySelectorAll('form :invalid');

        // Reset custom validation on all invalid fields (otherwise won't attempt to perform server side validation)
        invalidFields.forEach(this.resetCustomValidation);

        this.#hideFormValidationMessage();

        if (form.checkValidity() === false) {
            return;
        }

        let payload;
        if (this.model.isNew()) {
            // Use entire model contents
            payload = this.model.toJSON();
        } else {
            // Get model changes since last snapshot
            payload = this.model.changedAttributesSinceSnapshot();
        }

        // If no changes; immediately switch to read mode
        if (_.isEmpty(payload)) {
            this.switchReadMode();
        } else {
            // Save new value
            this.model
                .save(payload, { patch: true, wait: true })
                .then((response) => {
                    if (response.id) {
                        this.#disablePromptBeforeUnload();

                        // Navigate to edit deal
                        history.navigate('deals/edit/' + response.id, {
                            trigger: true,
                        });
                    } else {
                        this.switchReadMode();
                    }
                })
                .catch(({ responseJSON }) => {
                    // If type of error is App\Exceptions\Validation
                    if (responseJSON.type === 'App\\Exceptions\\Validation') {
                        for (const field of responseJSON.fields) {
                            const fieldEl = this.el.querySelector(
                                `[name="${field.name}"]`,
                            );

                            // Set validation error in invalid feedback slot
                            const errorFeedback =
                                fieldEl.parentElement.querySelector(
                                    '.invalid-feedback',
                                );
                            if (errorFeedback) {
                                errorFeedback.innerText = field.message;
                            }

                            // Set validation error on fields
                            fieldEl.setCustomValidity(field.message);
                        }

                        this.#showValidationMessage();
                    }
                });
        }
    }

    copy() {
        console.debug('DealEditView#copy');

        // Display confirmation box to user
        if (confirm('Are you sure you want to copy this deal?')) {
            OAuth2Client.fetchJSON(`${config.api.url}deals/${this.model.id}`, {
                method: 'COPY',
            }).then((responseBody) => {
                // Redirect to edit deal
                history.navigate('deals/edit/' + responseBody.id, {
                    trigger: true,
                });
            });
        }
    }

    delete() {
        console.debug('DealEditView#delete');

        // Display confirmation box to user. If accepted; destroy model and navigate to deal search
        if (
            confirm(
                'Are you sure you want to delete this deal? This cannot be undone!',
            )
        ) {
            this.model.destroy({
                success: function () {
                    history.navigate('deals', { trigger: true });
                },
                error: function (model, response) {
                    alert(response.responseText);
                },
            });
        }
    }

    generatePdf() {
        /**
         * @todo Consider replacing with a simple call to OAuth2Client.download(), to avoid creating blob URLs that cannot be cleaned up
         */
        OAuth2Client.fetch(
            `${config.api.url}generate/deal/${this.model.id}`,
            {},
        )
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.blob();
                }
                // Else; return rejected promise
                else {
                    return response
                        .json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((blob) => {
                // Create blob URL
                const url = window.URL.createObjectURL(blob);
                // Create temporary <a> element
                const tmpAnchor = document.createElement('a');
                document.body.appendChild(tmpAnchor);
                // Set URL
                tmpAnchor.href = url;
                // Set target
                tmpAnchor.target = '_blank';
                // Trigger click
                tmpAnchor.click();
                // Remove temporary <a> element
                document.body.removeChild(tmpAnchor);
            });
    }

    changelog() {
        console.debug('DealEditView#changelog');

        // Create modal view, with collection
        this.subviews.changelogView = new ChangelogsView({
            collection: new ChangelogsCollection(),
        });

        // Fetch collection
        this.subviews.changelogView.collection.fetch({
            data: {
                resource_type: 'deal',
                resource_id: this.model.get('id'),
            },
        });
    }

    handleFilesClick() {
        console.debug('DealEditView#handleFilesClick');

        this.subviews.filesModal.render();

        // Trigger fetch on collection
        this.subviews.filesModal.collection.fetch({
            data: {
                order: 'last_modified_at',
                include: ['last_modified_by'],
            },
        });
    }

    handleEditClick(e) {
        console.debug('DealEditView#handleEditClick');

        e.stopPropagation();

        this.switchEditMode();
    }

    handleCancelClick(e) {
        console.debug('DealEditView#handleCancelClick');

        e.stopPropagation();

        this.model.restoreFromSnapshot();

        this.#hideFormValidationMessage();

        this.switchReadMode();
    }

    handleSaveClick(e) {
        console.debug('DealEditView#handleSaveClick');

        e.stopPropagation();

        this.save();
    }
}
