import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

// Export the "Company" Bloodhound suggestion engine
export default new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('company_name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        transport: function (options, onSuccess, onError) {
            OAuth2Client.ajax(options).then(onSuccess).catch(onError);
        },
        url: config.api.url + 'companies',
        prepare: function (query, settings) {
            settings.data = {
                company_name: query,
                order: 'company_name',
            };

            return settings;
        },
        transform: function (response) {
            const results = [];
            // Add "company" type to each result
            response.results.forEach((result) => {
                result._type = 'company';
                results.push(result);
            });
            return results;
        },
    },
    identify: function (obj) {
        return obj.id;
    },
});
