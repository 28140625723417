import config from '@/js/app/config';
import { Collection } from 'backbone';

export default class Report3nosukeResultsCollection extends Collection {
    preinitialize() {
        this.url = config.api.url + 'units';
    }

    parse(response) {
        return response.results;
    }
}
