import UploadStatusView from './views/upload-status';
import OAuth2Client from '../oauth2-client';

export default function (file, xhrOptions, options) {
    const promise = new Promise((resolve, reject) => {
        console.debug('uploader');

        // Make sure file is Blob object
        /* Note: Using "instanceof File" doesn't work on some browsers, but Blob works everywhere. */
        if (!(file instanceof Blob)) {
            throw 'Parameter "file" is not instance of Blob';
        }

        /**
         * @todo Make a custom wrapper for XHR, part of OAuth2Client:
         * - Return a promise (resolve or reject)
         * - Emits standard XHR events (progress, load, etc)
         * - Emits custom events (done, fail) to match promise
         */
        const xhr = new XMLHttpRequest();

        // Create upload status for current file
        const uploadStatus = new UploadStatusView({
            file: file,
            request: xhr,
        });

        // Append upload status partial to upload status container
        options.uploadStatusContainer.append(uploadStatus.render().el);

        // If file size > 25MB
        if (file.size > 26214400) {
            const error = new Error('File too large');

            // Trigger "fail" event
            xhr.dispatchEvent(
                new Event('error', {
                    detail: error,
                }),
            );

            // Reject
            reject(error);
        }

        /**
         * Setup event listeners
         */
        xhr.addEventListener('load', () => {
            // If status is 2xx; resolve
            if (xhr.status.toString()[0] === '2') {
                // Trigger "success" event
                xhr.dispatchEvent(new CustomEvent('success'));

                resolve(xhr.response);
            }
            // Else; reject
            else {
                // Create error
                const error = new Error(xhr.response.message);
                error.name = xhr.response.type || xhr.response.error;

                // Trigger ErrorEvent
                xhr.dispatchEvent(
                    new ErrorEvent('error', {
                        error: error,
                    }),
                );

                reject(error);
            }
        });
        xhr.addEventListener('error', () => {
            // Create error and reject promise
            reject(new Error('Error connecting to server'));
        });
        xhr.addEventListener('abort', () => {
            // Create error and reject promise
            const error = new Error('Upload cancelled');
            error.name = 'Abort';
            reject(error);
        });

        // Check for OAuth2 token
        OAuth2Client.getToken().then(() => {
            // Open
            xhr.open(xhrOptions.method, xhrOptions.url, true);

            // Set Content-Type header
            xhr.setRequestHeader('Content-Type', file.type);

            // Set to accept JSON response
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.responseType = 'json';

            // Add "Authorization" header
            const token = JSON.parse(localStorage.getItem('hj-access-token'));
            if (token && typeof token === 'object') {
                xhr.setRequestHeader(
                    'Authorization',
                    token.token_type + ' ' + token.access_token,
                );
            }

            // Send request
            xhr.send(file);
        });
    });

    return promise;
}
