import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasets/company';
import config from '@/js/app/config';
import { getCompanyLink, getCompanyName } from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Preferences from '@/js/app/preferences';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasources/company';
import { TypeaheadCompanyDefaults } from '@/js/app/typeahead/defaults';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import propertyRestoreSelectedService from '../services/restore-selected';
import template from '../templates/search.html';
import PropertyLocationPickerView from './location-picker';
import PropertySearchResultsView from './search-results';

export default class PropertySearchView extends View {
    preinitialize(options) {
        this.options = options || {};
        this.options.bulkedit = true;

        this.events = {
            'submit #search form': this.submitSearch,
            'click [data-action="reset"]': this.handleReset,
        };

        this.subviews = {
            locationPickerView: new PropertyLocationPickerView(),
            results: new PropertySearchResultsView({
                format:
                    'undefined' !== typeof this.options.mode
                        ? this.options.mode
                        : 'long',
                buttons: {
                    type: 'unit',
                    add_to_list: true,
                    send: true,
                    print: true,
                    bulkedit: true,
                },
            }),
            agentCompanyTypeahead: new UiTypeaheadView({
                id: 'field-agent_company',
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                formatter: getCompanyName,
                urlGenerator: getCompanyLink,
            }),
            pmCompanyTypeahead: new UiTypeaheadView({
                id: 'field-pm_company',
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                formatter: getCompanyName,
                urlGenerator: getCompanyLink,
            }),
        };
    }

    initialize() {
        this.render();

        /* reset the selected property list */
        propertyRestoreSelectedService([]);
    }

    render() {
        this.el.innerHTML = template({
            options: this.options,
            Preferences: Preferences,
        });

        // Expand results container to take full width of screen
        const resultsContainer = this.el.querySelector('.container-results');
        resultsContainer.style.width = `${$(document).width() - 16}px`;
        resultsContainer.style.marginLeft = `-${$('div.container').offset().left + 8}px`;

        this.subviews.agentCompanyTypeahead
            .setElement(this.el.querySelector('#cntr-agent_company_id'))
            .render();
        this.subviews.pmCompanyTypeahead
            .setElement(this.el.querySelector('#cntr-pm_company_id'))
            .render();

        // Add placeholder to typeahead fields
        this.$el.find('.tt-input').prop('placeholder', '\uf002');

        this.subviews.locationPickerView
            .setElement(this.el.querySelector('#location'))
            .render();

        // Get search criteria from sessionStorage
        this.propertySearchCriteria = JSON.parse(
            sessionStorage.getItem('propertySearchCriteria'),
        );

        const promises = [];

        // If search conditions exists
        if (this.propertySearchCriteria) {
            const { agent_company, pm_company } = this.propertySearchCriteria;

            populateForm(
                this.el.querySelector('form'),
                this.propertySearchCriteria,
            );

            if (agent_company || pm_company) {
                const query = {
                    id: [],
                };

                // If ID exists, push to query.id array
                agent_company && query.id.push(agent_company);
                pm_company && query.id.push(pm_company);

                const url = new URL(config.api.url + 'companies');
                url.search = jQuery.param(query);

                // Fetch information for given staff ID
                const fetchCompanies = OAuth2Client.fetchJSON(url, {}).then(
                    (response) => {
                        response.results.forEach((result) => {
                            if (result.id === agent_company) {
                                this.subviews.agentCompanyTypeahead.setDatum(
                                    result,
                                );
                            }

                            if (result.id === pm_company) {
                                this.subviews.pmCompanyTypeahead.setDatum(
                                    result,
                                );
                            }
                        });
                    },
                );

                promises.push(fetchCompanies);
            }
        }

        Promise.all(promises).then(() => {
            // Trigger submit
            this.$el.find('#search form').trigger('submit');
        });

        return this;
    }

    submitSearch($e) {
        $e.preventDefault();

        const criteria = {
            sort_key: Preferences.sort_key,
            sort_order: Preferences.sort_order,
            limit: Preferences.limit,
            offset: 0,
        };

        const form = this.el.querySelector('form');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        // If search form has selections
        if (!_.isEmpty(this.subviews.locationPickerView.selectedLocations)) {
            switch (this.subviews.locationPickerView.locationType) {
                case 'wards_and_neighborhoods': {
                    // Get cities and neighborhoods, and add as conditions
                    const locations =
                        this.subviews.locationPickerView.getWardsNeighborhoods();
                    criteria.city = locations.cities;
                    criteria.neighborhood = locations.neighborhoods;
                    break;
                }

                case 'train_lines_and_stations':
                    // Get stations, and add as "landmark" condition
                    criteria.landmark =
                        this.subviews.locationPickerView.getStations();
                    criteria.group_by = 'id';
                    break;

                case 'custom_areas':
                    // Get custom areas, and add as "distance from" condition
                    criteria.distance_from =
                        this.subviews.locationPickerView.getCustomAreas();
                    break;
            }
        }

        // If company selected, add datum id
        if (this.subviews.agentCompanyTypeahead.datum) {
            criteria.agent_company =
                this.subviews.agentCompanyTypeahead.datum.id;
        }

        // If company selected, add datum id
        if (this.subviews.pmCompanyTypeahead.datum) {
            criteria.pm_company = this.subviews.pmCompanyTypeahead.datum.id;
        }

        // $(window).scrollTop($('.mini-layout h2').offset().top - 50);

        // Reset the selected property list
        propertyRestoreSelectedService([]);

        // Set query details on subview
        this.subviews.results.query = criteria;

        sessionStorage.setItem(
            'propertySearchCriteria',
            JSON.stringify(criteria),
        );

        // Set element and render
        this.subviews.results
            .setElement(this.el.querySelector('#results'))
            .render()
            .fetch();
    }

    handleReset(e) {
        e.preventDefault();

        this.el.querySelector('form').reset();

        this.el.querySelectorAll('input[type="checkbox"]').forEach((input) => {
            input.checked = false;
        });

        this.subviews.agentCompanyTypeahead.clear();
        this.subviews.pmCompanyTypeahead.clear();

        sessionStorage.removeItem('propertySearchCriteria');
    }
}
