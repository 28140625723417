import { View } from 'backbone';
import PeopleCollection from '@/js/app/people/collections/people';
import ClientCriteria from '../criterias/client';
import template from '../templates/all.html';
import ClientResultsView from './results';

export default class ClientAllView extends View {
    preinitialize() {
        // Create criteria model
        this.criteria = new ClientCriteria();

        // Create people collection
        this.peopleCollection = new PeopleCollection();

        // Create subviews
        this.subviews = {
            clientsResults: new ClientResultsView({
                collection: this.peopleCollection,
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        this.render();

        // Force client search
        this.subviews.clientsResults.search();
    }

    render() {
        console.debug('ClientAllView#render');

        // Render template
        this.el.innerHTML = template();

        // Attach clients results view to DOM
        this.subviews.clientsResults
            .setElement(this.$el.find('#results'))
            .render();

        return this;
    }
}
