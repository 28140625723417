export default function (pid) {
    // If length <= 2, too short; return
    if (pid.length <= 2) {
        return;
    }

    // Get prefix
    const prefix = pid.substr(0, 2);

    // If prefix is numeric, we cannot determine the type; return
    if (Number(prefix)) {
        return;
    }

    // Get ID
    const id = Number(pid.substr(2));

    // If not a number; return
    if (isNaN(id)) {
        return;
    }

    return {
        type: prefix[0],
        structure_type: prefix[1],
        id,
    };
}
