import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import templateSpinner from '@/js/app/ui/templates/spinner.html';
import templateError from '@/js/app/ui/templates/error.html';
import template from '../templates/results.html';

export default class Report3nosukeResultsView extends View {
    preinitialize() {}

    initialize() {
        // When collection changes; render
        this.listenTo(this.collection, 'change', this.render);

        // When collection makes request, show spinner
        this.listenTo(this.collection, 'request', this.handleCollectionRequest);

        // When collection request finishes, stop spinner
        this.listenTo(this.collection, 'sync', this.handleCollectionSync);

        // If collection errors, show error message
        this.listenTo(this.collection, 'error', this.handleCollectionError);
    }

    render() {
        console.debug(Report3nosukeResultsView.name + '#render');

        if (this.loading) {
            this.el.innerHTML = templateSpinner();
        } else if (this.error) {
            this.el.innerHTML = templateError();
        } else {
            this.el.innerHTML = template({
                results: this.collection.toJSON(),
                Formatter,
            });
        }

        return this;
    }

    handleCollectionRequest() {
        this.loading = true;

        this.render();
    }

    handleCollectionSync() {
        this.loading = false;

        this.render();
    }

    handleCollectionError() {
        this.loading = false;
        this.error = true;

        this.render();
    }
}
