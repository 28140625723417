import { View } from 'backbone';

export default class Spinner extends View {
    constructor(options) {
        let color = '',
            top = 'calc(50% - 1rem)';
        if (options) {
            color = options.color;

            if (options.top) {
                top = '1rem';
            }
        }

        const props = {
            className: `spinner-border${color ? ` ${color}` : ''}`,
            attributes: {
                style: `top:${top}; left:calc(50% - 1rem); position:absolute; z-index:2000000000`,
                role: 'status',
            },
        };
        super(props);
        this.el.innerHTML = '<span class="sr-only">Loading...</span>';
    }

    spin(el) {
        this.containerEl = el;
        this.containerElPosition = this.containerEl.style.position;
        this.containerEl.style.position = 'relative';
        el.appendChild(this.el);
    }

    stop() {
        this.containerEl.style.position = this.containerElPosition;
        this.remove();
    }
}
