import { Model } from 'backbone';
import config from '@/js/app/config';

export default class UnitModel extends Model {
    urlRoot = config.api.url + 'units';

    #savedData = {};

    snapshot() {
        console.debug('UnitModel#snapshot');

        this.#savedData = this.toJSON();
    }

    changedAttributesSinceSnapshot() {
        console.debug('UnitModel#changedAttributesSinceSnapshot');

        const changedKeys = _.keys(this.changedAttributes(this.#savedData));
        return changedKeys.length === 0 ? false : this.pick(changedKeys);
    }

    restoreFromSnapshot() {
        console.debug('UnitModel#restoreFromSnapshot');

        this.set(this.#savedData);
    }
}
