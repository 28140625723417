import { history, View } from 'backbone';
import template from '../templates/modal_new.html';

export default class PeopleNewView extends View {
    preinitialize() {
        this.id = 'modal-new_person';

        this.className = 'modal fade';

        this.events = {
            'shown.bs.modal': this.buildup,
            'hidden.bs.modal': this.teardown,
            'submit form': this.create,
        };
    }

    initialize() {
        this.listenTo(this.model, 'invalid', this.displayError);
    }

    render() {
        console.debug('PeopleNewView#render');

        // Render template
        this.el.innerHTML = template();

        // Trigger modal display
        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: true,
        });
    }

    buildup() {
        console.debug('PeopleNewView#buildup');

        // Set focus
        this.el.querySelector('[name="first_name"]').focus();
    }

    create($e) {
        console.debug('PeopleNewView#create');

        $e.preventDefault();

        const errorAlertEl = this.el.querySelector('#errorAlert');
        errorAlertEl.classList.add('d-none');

        const data = {
            first_name: this.el.querySelector('#field-first_name').value,
            last_name: this.el.querySelector('#field-last_name').value,
            email: this.el.querySelector('#field-email').value,
        };

        this.model
            .save(data)
            .then((response) => {
                // Hide modal
                this.$el.modal('hide');

                // Redirect to edit person
                history.navigate('people/edit/' + response.id, {
                    trigger: true,
                });
            })
            .catch(({ responseJSON }) => {
                if (responseJSON.type === 'App\\Exceptions\\Validation') {
                    const fieldErrors = responseJSON.fields.map(
                        (f) => f.message,
                    );
                    errorAlertEl.innerHTML = fieldErrors.join('.<br>');
                    errorAlertEl.classList.remove('d-none');
                } else {
                    errorAlertEl.innerText = 'Something went wrong.';
                    errorAlertEl.classList.remove('d-none');
                }
            });
    }

    displayError(model, error) {
        console.debug('PeopleNewView#displayError');

        _.each(error, (v, k) => {
            this.$el
                .find('[name="' + k + '"]')
                .closest('.form-group')
                .addClass('is-invalid');
        });
    }

    teardown() {
        console.debug('PeopleNewView#teardown');

        this.$el.data('modal', null);

        // Remove view
        this.remove();
    }
}
