import { View } from 'backbone';
import numeral from 'numeral';
import populateForm from '@/js/libs/populate-form';
import template from '../templates/edit_criteria.html';
import PeopleAutomatchPropertiesModalView from './modal-automatch-properties';

export default class PeopleEditCriteriaView extends View {
    preinitialize(options) {
        this.tagName = 'form';

        this.events = {
            'change input,select,textarea': this.updateModel,
            'click [data-action="automatch"]': this.handleAutomatchClick,
        };

        this.subviews = {
            leasingAutomatchModal: new PeopleAutomatchPropertiesModalView({
                model: options.model,
                type: 'leasing',
            }),
            salesAutomatchModal: new PeopleAutomatchPropertiesModalView({
                model: options.model,
                type: 'sales',
            }),
        };
    }

    initialize() {
        _.bindAll(this, 'render');

        this.listenTo(this.model, 'change', this.updateOutlets);
        this.listenTo(this.model, 'change:is_tenant', this.toggleLeasingCard);
        this.listenTo(this.model, 'change:is_buyer', this.toggleSalesCard);
    }

    render() {
        console.debug('PeopleEditCriteriaView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            person: this.model.toJSON(),
        });

        populateForm(this.el, this.model.toJSON());

        return this;
    }

    updateModel($e) {
        console.debug('PeopleEditCriteriaView#updateModel');

        const t = $e.currentTarget;

        // If no name, return
        if (t.name === '') {
            return;
        }

        let value;
        // If type is checkbox; get property "checked"
        if (t.type === 'checkbox') {
            value = t.checked;
        }
        // Else if number type, or data attribute "numeral" exists; unformat number
        else if (t.type === 'number' || t.dataset.numeral) {
            value = numeral(t.value).value() || null;
        }
        // Else; use value
        else {
            if (t.value === '') {
                value = null;
            } else {
                value = t.value;
            }
        }

        // Save new value
        this.model.save(t.name, value, { patch: true, wait: true });
    }

    updateOutlets(model) {
        console.debug('PeopleEditCriteriaView#updateOutlets');

        // Loop through all changed attributes, and set value of input fields
        /** @todo What if <select> or <textarea>? */
        _.each(model.changedAttributes(), (v, k) => {
            const t = this.$el.find('[name="' + k + '"]');

            // If radio or checkbox, change checked property
            if (t.prop('type') === 'radio') {
                $('input[name="' + k + '"][value="' + v + '"]').prop(
                    'checked',
                    true,
                );
            } else if (t.prop('type') === 'checkbox') {
                $('input[name="' + k + '"]').prop('checked', !!v); // Force convert value to boolean
            }
            // Else, change value
            else {
                // If numeral data attribute exists, format appropriately
                if (t.data('numeral')) {
                    t.val(v ? numeral(v).format(t.data('numeral')) : '');
                } else {
                    t.val(v);
                }
            }
        });
    }

    handleAutomatchClick(e) {
        console.debug('PeopleEditCriteriaView#handleAutomatchClick');

        e.stopPropagation();

        const buttonDatasetCriteria = e.currentTarget.dataset.criteria;
        if (buttonDatasetCriteria === 'leasing') {
            this.subviews.leasingAutomatchModal.render();
        } else if (buttonDatasetCriteria === 'sales') {
            this.subviews.salesAutomatchModal.render();
        }
    }

    toggleLeasingCard(model) {
        if (model.get('is_tenant')) {
            this.el.querySelector('#leasingCard').classList.remove('d-none');
        } else {
            this.el.querySelector('#leasingCard').classList.add('d-none');
        }
    }

    toggleSalesCard(model) {
        if (model.get('is_buyer')) {
            this.el.querySelector('#salesCard').classList.remove('d-none');
        } else {
            this.el.querySelector('#salesCard').classList.add('d-none');
        }
    }
}
