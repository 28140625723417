import { Collection } from 'backbone';
import config from '@/js/app/config';
import BuildingFileModel from '../models/building-file';

export default Collection.extend({
    url: function () {
        return config.api.url + 'buildings/' + this.building_id + '/files';
    },

    initialize: function (models, options = {}) {
        this.building_id = options.building_id || null;
    },

    model: BuildingFileModel,
});
