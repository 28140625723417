import { View } from 'backbone';
import template from '../templates/file_upload_status.html';

export default class UploadStatusView extends View {
    preinitialize(options) {
        this.className = 'row';

        this.events = {
            'click [data-action="clear"]': this.clear,
        };

        this.file = options.file;

        this.request = options.request;

        this.progressBar = null;
    }

    initialize() {
        _.bindAll(
            this,
            'handleProgress',
            'handleSuccess',
            'handleError',
            'handleAbort',
        );

        // Add "progress" event listener on request object; update progress
        this.request.upload.addEventListener(
            'progress',
            this.handleProgress,
            false,
        );

        // Add "success" event listener on request object; complete upload
        this.request.addEventListener('success', this.handleSuccess, false);

        // Add "error" event listener on request object
        this.request.addEventListener('error', this.handleError, false);

        // Add "abort" event listener on request object
        this.request.addEventListener('abort', this.handleAbort, false);
    }

    render() {
        this.el.innerHTML = template(this.file);

        /** @todo Make progressbar a subview */
        this.progressBar = this.$el.find('.progress .progress-bar');

        return this;
    }

    clear($e) {
        $e.preventDefault();
        this.remove();
    }

    abort($e) {
        $e.preventDefault();

        /**
         * @todo Implement file size check on server side
         * Aborting an upload request doesn't stop processing
         * what was sent to the server. Need to send the file
         * size with the request, so the server can compare with
         * size of payload, and abort if size doesn't match.
         */

        // Abort request
        this.request.abort();

        // Hide "remove" button
        $e.currentTarget.style.display = 'none';
    }

    handleProgress(e) {
        if (e instanceof ProgressEvent && e.lengthComputable) {
            // Calculate progress percentage and update progress
            this.updateProgressBar((e.loaded / e.total) * 100);
        }
    }

    updateProgressBar(value) {
        // Set % width of progress bar
        this.progressBar.css('width', value + '%');
        this.progressBar.prop('aria-valuenow', value);

        return this;
    }

    handleSuccess() {
        // Change color of progressbar
        /** @todo Make progressbar a subview */
        this.progressBar
            .addClass('bg-success')
            .removeClass('progress-bar-striped progress-bar-animated');

        // After 800ms, remove view
        setTimeout(() => {
            this.remove();
        }, 800);
    }

    handleError(e) {
        // Set progress to 100%
        this.updateProgressBar(100);

        // Change color of progressbar
        /** @todo Make progressbar a subview */
        this.progressBar
            .addClass('bg-danger')
            .removeClass('progress-bar-striped progress-bar-animated');

        // Determine error message
        let message;
        if (e instanceof ErrorEvent) {
            message = e.error.message;
        } else {
            message = 'Unable to connect to server. Please try again.';
        }

        // Display error message
        this.$el.find('.status-text').text(message);

        // Show "remove" button
        this.$el.find('[data-action="clear"]').removeClass('d-none');
    }

    handleAbort() {
        // Change color of progressbar
        /** @todo Make progressbar a subview */
        this.progressBar
            .addClass('bg-danger')
            .removeClass('progress-bar-striped progress-bar-animated');

        // Display status message
        this.$el.find('.status-text').text('Cancelled.');

        // After 2000ms, remove view
        setTimeout(() => {
            this.remove();
        }, 2000);
    }
}
