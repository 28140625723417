import { Model } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

const DealFileModel = Model.extend({
    idAttribute: 'filename',

    urlRoot: function () {
        return config.api.url + 'deals/' + this.deal_id + '/files';
    },

    initialize: function (attrs, options) {
        // Set deal ID attribute in model
        this.deal_id = options.deal_id || options.collection.deal_id || null;
    },

    defaults: function () {
        return {
            deal_id: null,
            filename: null,
            mime_type: null,
            size: null,
            content_md5: null,
            last_modified_at: null,
            last_modified_by_id: null,
        };
    },

    download: function () {
        OAuth2Client.download(
            this.url(),
            {
                method: 'DOWNLOAD',
            },
            {
                filename: this.get('filename'),
            },
        );
    },
});

export default DealFileModel;
