/**
 * @typedef {Object} OAuth2Token
 * @property {string} token_type
 * @property {number} timestamp
 * @property {number} expires_in
 * @property {string} access_token
 * @property {string} refresh_token
 */

/**
 * @typedef {Object} OAuth2ClientOptions
 * @property {string} url
 * @property {string} client_id
 * @property {string} client_secret
 * @property {string} token_name
 * @property {function} onRestoreToken
 * @property {function} onAuthenticate
 * @property {function} onTokenRefresh
 * @property {function} onTokenRefreshFail
 */

/**
 * @typedef {Object} OAuth2RequestPayload
 * @property {string} grant_type
 * @property {string} client_id
 * @property {string} client_secret
 * @property {string} [username]
 * @property {string} [password]
 * @property {string} [refresh_token]
 */

export default class OAuth2Client {
    /**
     * @type {OAuth2ClientOptions}
     */
    #options;

    /**
     * @type {Promise<OAuth2Token> | undefined}
     */
    #token;

    /**
     * @type {boolean}
     */
    #tokenResolved = false;

    /**
     * @type {number | undefined}
     */
    #tokenExpiresAt;

    /**
     * @param {OAuth2ClientOptions} options
     */
    constructor(options = {}) {
        // Merge options with defaults
        this.#options = _.extend(
            {
                url: '',
                client_id: null,
                client_secret: null,
                token_name: 'access-token',
                onRestoreToken: _.noop,
                onAuthenticate: _.noop,
                onTokenRefresh: _.noop,
                onTokenRefreshFail: _.noop,
            },
            options,
        );
    }

    /**
     * Restore token
     * @return {Promise<any>|void}
     */
    restoreToken() {
        console.debug('OAuth2Client#restoreToken');

        /** @type {OAuth2Token | null} */
        const token = JSON.parse(
            localStorage.getItem(this.#options.token_name),
        );

        // If we have a token in local storage, resolve immediately
        if (token) {
            this.#token = Promise.resolve(token);
            this.#tokenResolved = true;
            this.#tokenExpiresAt = token.timestamp + token.expires_in;

            if (this.#options.onRestoreToken) {
                return this.#options.onRestoreToken();
            }
        }
    }

    /**
     * Authenticate against OAuth2 server
     * Note: Returns a promise so that calling code can await result of authentication
     * @param {string} username - The user's identity
     * @param {string} password - The user's password
     * @return {Promise<any>} Resolved value will be return value of onAuthenticate callback
     */
    authenticate(username, password) {
        console.debug('OAuth2Client#authenticate');

        // Initiate fetch of token with username and password (replaces this.#token)
        this.#fetchToken({
            grant_type: 'password',
            username: username,
            password: password,
        });

        return this.#token.then(this.#options.onAuthenticate);
    }

    /**
     * Does the OAuth2 client have a token?
     */
    hasToken() {
        console.debug('OAuth2Client#hasToken');

        return this.#token instanceof Promise;
    }

    /**
     * Is the OAuth2 client's token expired?
     */
    #isTokenExpired() {
        console.debug('OAuth2Client#isTokenExpired');

        // Return whether token is expired (with 10 second buffer to account for system time drift)
        return this.#tokenExpiresAt - 10 <= Math.floor(Date.now() / 1000);
    }

    /**
     * Is the OAuth2 client's token resolved?
     * @todo If/when native Promises let us access their state, this won't be necessary...
     */
    #isTokenResolved() {
        console.debug('OAuth2Client#isTokenResolved');

        // Return whether token is resolved
        return this.#tokenResolved;
    }

    /**
     * Get existing OAuth2 token, or refresh if token expired
     */
    getToken() {
        console.debug('OAuth2Client#getToken');

        // If token exists
        if (this.hasToken()) {
            // If token is expired and resolved
            if (this.#isTokenExpired() && this.#isTokenResolved()) {
                // Initiate fetch of new token with refresh token (replaces this.#token)
                // Note: Does NOT await or chain returned, since the onTokenRefresh hook needs to happen in the background
                this.#refreshToken();
            }

            // Return promise for token
            return this.#token;
        }
        // Else no token
        else {
            return Promise.reject(new Error('No token'));
        }
    }

    /**
     * Refresh token
     * @return {Promise<any>} Resolved value will be return value of onTokenRefresh callback
     */
    #refreshToken() {
        console.debug('OAuth2Client#refreshToken');

        // Initiate fetching of token with refresh token (replaces this.#token)
        this.#fetchToken({
            grant_type: 'refresh_token',
            refresh_token: JSON.parse(
                localStorage.getItem(this.#options.token_name),
            ).refresh_token,
        });

        return this.#token.then(this.#options.onTokenRefresh).catch((err) => {
            console.warn('Error while refreshing token:', err);

            return this.#options.onTokenRefreshFail();
        });
    }

    /**
     * Fetch token from OAuth2 server
     * Note: stores token promise in class property `ths.#token`
     * @param {OAuth2RequestPayload} payload - Payload for fetch request
     */
    #fetchToken(payload = {}) {
        console.debug('OAuth2Client#fetchToken');

        // Create payload
        payload = _.extend(
            {
                client_id: this.#options.client_id,
                client_secret: this.#options.client_secret,
            },
            payload,
        );

        // Fetch token
        this.#tokenResolved = false;
        this.#token = fetch(this.#options.url + 'access_token', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(payload).toString(),
        })
            .catch((err) => {
                // Log error message
                console.error(err.message);

                throw new Error(
                    'OAuth2Client#fetchToken: Unable to fetch token',
                );
            })
            .then((response) => {
                // If response is OK; return JSON
                if (response.ok) {
                    return response.json();
                }
                // Else; throw OAuth2Error
                else {
                    return response.json().then((error) => {
                        // Flag token as resolved
                        this.#tokenResolved = true;

                        throw new OAuth2Error(error.message, error.error);
                    });
                }
            })
            .then((/** @type {OAuth2Token} */ token) => {
                console.debug('#fetchToken - Storing token');

                // Set token timestamp
                token.timestamp = Math.floor(Date.now() / 1000);

                // Store access token in localStorage
                localStorage.setItem(
                    this.#options.token_name,
                    JSON.stringify(token),
                );

                // Set token expiry timestamp
                this.#tokenExpiresAt = token.expires_in + token.timestamp;

                // Flag token as resolved
                this.#tokenResolved = true;

                return token;
            });
    }

    /**
     * Clear token
     */
    clearToken() {
        console.debug('OAuth2Client#clearToken');

        // Remove access token from self
        this.#token = undefined;

        // Remove access token from localStorage
        localStorage.removeItem(this.#options.token_name);
    }

    /**
     * Wrapper for jQuery.ajax
     * @param {object} options - Options for request
     * @returns {JQuery.jqXHR}
     */
    ajax(options) {
        console.debug('OAuth2Client#ajax');

        console.debug('Ajax request for URL:', options.url);

        // Get OAuth2 token
        return this.getToken().then((token) => {
            // Add "Authorization" header
            options.headers = _.extend({}, options.headers, {
                Authorization: token.token_type + ' ' + token.access_token,
            });

            // Initiate ajax call
            return jQuery
                .ajax(options)
                .fail((jqXHR, textStatus, errorThrown) => {
                    if (jqXHR.readyState === 0) {
                        alert('Connection error.');
                        throw new Error('Connection error');
                    } else if (jqXHR.readyState === 4) {
                        if (jqXHR.status >= 500) {
                            alert('Server error.');
                            throw new Error(
                                'OAuth2Client#ajax: Error in AJAX response',
                                errorThrown,
                            );
                        }
                    }
                });
        });
    }

    /**
     * Fetch wrapper
     * @param {string | URL | globalThis.Request} input
     * @param {RequestInit} init
     */
    fetch(input, init) {
        console.debug('OAuth2Client#fetch');

        return this.getToken().then((token) => {
            // Add "Authorization" header
            init.headers = _.extend({}, init.headers, {
                Authorization: token.token_type + ' ' + token.access_token,
            });

            return fetch(input, init).catch(() => {
                alert('Connection error.');
                throw new Error('Connection error');
            });
        });
    }

    /**
     * Fetch JSON
     * @param {string} resource
     * @param {RequestInit} init
     * @returns Promise<any>
     */
    fetchJSON(resource, init = {}) {
        console.debug('OAuth2Client#fetchJSON');

        console.debug('FetchJSON request for URL:', resource);

        init.headers = _.extend({}, init.headers, {
            Accept: 'application/json',
        });
        return this.fetch(resource, init).then((response) => {
            // If response is OK; return JSON
            if (response.ok) {
                if (response.status === 204) {
                    return;
                } else {
                    return response.json();
                }
            }
            // Else; return rejected promise
            else {
                return response.json().then(Promise.reject);
            }
        });
    }

    /**
     * Download file using fetch
     * @param {object} resource
     * @param {object} init
     * @param {object} options
     * @return {promise}
     */
    download(resource, init, options) {
        // Proxy to fetch
        return this.fetch(resource, init)
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.blob();
                }
                // Else; return rejected promise
                else {
                    return response
                        .json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((blob) => {
                // Create blob URL
                const url = window.URL.createObjectURL(blob);
                // Create temporary <a> element
                const tmpAnchor = document.createElement('a');
                document.body.appendChild(tmpAnchor);
                // Set download filename
                tmpAnchor.download = options.filename;
                // Set URL
                tmpAnchor.href = url;
                // Trigger click
                tmpAnchor.click();
                // Remove temporary <a> element
                document.body.removeChild(tmpAnchor);
                // Remove blob URL
                window.URL.revokeObjectURL(url);
            });
    }

    csvDownload(resource, init, options) {
        console.debug('OAuth2Client#csvDownload');

        // Add accept header
        init.headers = _.extend({}, init.headers, {
            Accept: 'text/csv',
        });

        return this.download(resource, init, options);
    }

    xlsxDownload(resource, init, options) {
        console.debug('OAuth2Client#xlsxDownload');

        // Add accept header
        init.headers = _.extend({}, init.headers, {
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        return this.download(resource, init, options);
    }
}

class OAuth2Error extends Error {
    constructor(message, error) {
        // Call Error class contructor
        super(message);
        this.name = 'OAuth2Error';

        // Assign code property
        this.error = error;
    }
}
