export default function (p_ids) {
    // If no argument, return selected properties from local storage
    if (!p_ids) {
        return JSON.parse(localStorage.getItem('selected_property')) || [];
    } else {
        // Convert IDs to numbers
        p_ids = p_ids.map((id) => Number(id));

        // Save selected properties in local storage
        localStorage.setItem('selected_property', JSON.stringify(p_ids));
    }
}
