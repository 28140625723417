import { View } from 'backbone';
import ReportMotozukeResultsCollection from '../collections/results';
import template from '../templates/index.html';
import ReportMotozukeResultsView from './results';

class ReportMotozukeIndexView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'reports';

        this.criteria = {
            sort_key: undefined,
            sort_order: 'asc',
        };

        this.resultsCollection = new ReportMotozukeResultsCollection();

        // Create subviews
        this.subviews = {
            resultsView: new ReportMotozukeResultsView({
                collection: this.resultsCollection,
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        this.render();

        // Initiate fetch on results collection
        this.resultsCollection.fetch();
    }

    render() {
        console.debug('ReportMotozukeIndexView#render');

        // Render template
        this.el.innerHTML = template();

        // Bind results view and render
        this.subviews.resultsView
            .setElement(this.el.querySelector('#results'))
            .render();

        return this;
    }
}

export default ReportMotozukeIndexView;
