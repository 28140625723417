import * as Formatter from '@/js/app/formatter';
import numeral from 'numeral';

export default function (query = false) {
    if (!_.isEmpty(query) && _.isEmpty(query.id)) {
        let bedrooms = '';
        if (
            (!_.isEmpty(query.bedroom_no) && query.bedroom_no.length > 0) ||
            0 == query.bedroom_no
        ) {
            bedrooms = 'with ';
            jQuery.each(query.bedroom_no, function (index, rooms) {
                bedrooms +=
                    4 == rooms
                        ? '4+'
                        : rooms < 4
                          ? 0 == rooms
                              ? 'studio, '
                              : rooms + ', '
                          : '';
            });

            if (0 === query.bedroom_no) {
                bedrooms += 'studio, ';
            }

            if (', ' == bedrooms.substr(bedrooms.length - 2, 2)) {
                bedrooms = bedrooms.substr(0, bedrooms.length - 2);
            }
            if (query.bedroom_no.length > 1 && 4 != query.bedroom_no[0]) {
                if ('4+' == bedrooms.substr(bedrooms.length - 2, 2)) {
                    bedrooms =
                        bedrooms.substr(0, bedrooms.length - 2) +
                        ' or ' +
                        bedrooms.substr(bedrooms.length - 2);
                } else {
                    bedrooms =
                        bedrooms.substr(0, bedrooms.length - 1) +
                        ' or ' +
                        bedrooms.substr(bedrooms.length - 1);
                }
            }
            if ('with studio' != bedrooms) {
                bedrooms += ' bedrooms';
            }
        }

        let structure = !_.isEmpty(query.structure_type)
                ? 'land' == query.structure_type
                    ? Formatter.structure(query.conditions)
                    : Formatter.structure(query.conditions)
                          .toLowerCase()
                          .capitalize()
                : 'Unit or House',
            type = 'for ' + ('sales' == query.property_type ? 'sale' : 'rent'),
            location = '',
            price =
                'rent' == query.property_type
                    ? !_.isEmpty(query.rent) && !_.isEmpty(query.rent.high)
                        ? 'between ' +
                          Formatter.currency() +
                          numeral(query.rent.low).format('0,0') +
                          ' - ' +
                          Formatter.currency() +
                          numeral(query.rent.high).format('0,0')
                        : Formatter.currency() +
                          numeral(
                              !_.isEmpty(query.rent) ? query.rent.low : 0,
                          ).format('0,0') +
                          ' and up'
                    : !_.isEmpty(query.price) && !_.isEmpty(query.price.high)
                      ? 'between ' +
                        Formatter.currency() +
                        numeral(query.price.low).format('0,0') +
                        ' - ' +
                        Formatter.currency() +
                        numeral(query.price.high).format('0,0')
                      : Formatter.currency() +
                        numeral(
                            !_.isEmpty(query.price) ? query.price.low : 0,
                        ).format('0,0') +
                        ' and up',
            size =
                !_.isEmpty(query.size) && !_.isEmpty(query.size.high)
                    ? Math.round(query.size.low) +
                      'm&sup2; - ' +
                      Math.round(query.size.high) +
                      'm&sup2;'
                    : Math.round(!_.isEmpty(query.size) ? query.size.low : 0) +
                      'm&sup2; or larger';

        const counter = 0;
        if (counter > 1) {
            location =
                location +
                ' (+' +
                (counter - 1) +
                ' other area' +
                (counter - 1 > 1 ? 's' : '') +
                ')';
        }

        if (!_.isEmpty(query.city)) {
            location =
                'in ' +
                query.city.length +
                ' Ward' +
                (query.city.length > 1 ? 's' : '');
            if (!_.isEmpty(query.neighborhood)) {
                location =
                    location +
                    ' (within ' +
                    query.neighborhood.length +
                    ' neighborhood' +
                    (query.neighborhood.length > 1 ? 's' : '') +
                    ')';
            }
        }

        return (
            structure +
            ' ' +
            type +
            (bedrooms.length > 0 ? ', ' + bedrooms : '') +
            ', ' +
            price +
            ', ' +
            size +
            ' ' +
            location
        );
    } else {
        return 'Undefined Search';
    }
}
