import { Model } from 'backbone';
import config from '@/js/app/config';

export default Model.extend({
    urlRoot: config.api.url + 'companies',

    // Validate attributes
    validate: function (attrs) {
        console.debug('CompanyModel#validate');

        const errors = {};

        // Ensure company name not blank
        if (_.isEmpty(attrs.company_name)) {
            errors.company_name = 'Company name cannot be blank';
        }

        return _.isEmpty(errors) ? null : errors;
    },
});
