import numeral from 'numeral';

const separator = '.';

export default function formToObject(form, removeFalseValues = false) {
    const data = {};
    const fieldList = form.querySelectorAll('input,select,textarea');

    for (const field of fieldList) {
        if (
            field.name === '' ||
            field.disabled ||
            field.dataset.partial !== undefined
        ) {
            continue;
        }

        let value;
        const fieldHasGroup =
            form.querySelectorAll(
                '[name="' + field.name + '"][type="checkbox"]',
            ).length > 1;

        // If field has group and is undefined
        if (fieldHasGroup && data[field.name] === undefined) {
            // Initialize with blank array
            attachValue(data, field.name, []);
        }

        // If field type is checkbox
        if (field.type === 'checkbox') {
            if (fieldHasGroup) {
                if (field.checked) {
                    if (field.value === '') {
                        value = null;
                    } else if (typeof field.dataset.number !== 'undefined') {
                        value = numeral(field.value).value();
                    } else {
                        value = field.value;
                    }
                } else {
                    continue;
                }
            } else {
                if (field.checked) {
                    const fieldValue = field.value;

                    if (fieldValue) {
                        if (typeof field.dataset.number !== 'undefined') {
                            value = numeral(fieldValue).value();
                        } else {
                            value = fieldValue;
                        }
                    } else {
                        value = true;
                    }
                } else {
                    const uncheckedValue = field.dataset.uncheckedValue;

                    if (uncheckedValue) {
                        if (typeof field.dataset.number !== 'undefined') {
                            value = numeral(uncheckedValue).value();
                        } else {
                            value = uncheckedValue;
                        }
                    } else {
                        value = false;
                    }
                }
            }
        }

        // Else if field type is radio
        else if (field.type === 'radio') {
            if (field.checked) {
                if (field.value === '') {
                    value = null;
                } else if (typeof field.dataset.number !== 'undefined') {
                    value = numeral(field.value).value();
                } else {
                    value = field.value;
                }
            } else {
                continue;
            }
        }

        // Else
        else {
            if (field.value === '') {
                value = null;
            } else if (
                field.type === 'number' ||
                typeof field.dataset.number !== 'undefined'
            ) {
                value = field.value.replace(/\D/g, '');

                if (field.value === '') {
                    value = null;
                } else {
                    value = numeral(field.value).value();
                }
            } else {
                value = field.value;
            }
        }

        // Attach value somewhere in data object
        attachValue(data, field.name, value);
    }

    if (removeFalseValues) {
        removeEmptyOrNull(data);
    }

    // Replace empty arrays with null
    // removeEmptyArrays(data);

    return data;
}

function attachValue(data, fieldname, value) {
    if (fieldname.includes(separator)) {
        // Split field name by separator to get prefix and suffix
        const splitFieldname = fieldname.split(separator);
        const prefix = splitFieldname.shift();
        const suffix = splitFieldname.join(separator);

        // If prefix key doesn't yet exist, initialize as empty object
        if (data[prefix] === undefined) {
            data[prefix] = {};
        }

        // Attach value to suffix key of prefix object
        attachValue(data[prefix], suffix, value);
    } else {
        if (Array.isArray(data[fieldname])) {
            // if (value !== undefined) {
            data[fieldname].push(value);
            // }
        } else {
            data[fieldname] = value;
        }
    }
}

function removeEmptyOrNull(obj) {
    for (let key in obj) {
        if (obj[key] === null || obj[key] === false || obj[key] === '') {
            delete obj[key];
        }
    }
    return obj;
}

/*
function removeEmptyArrays(data) {
    for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value) && value.length === 0) {
            data[key] = null;
        } else if (_.isObject(value)) {
            removeEmptyArrays(value);
        }
    }
}
*/

// export const Numeric = '0123456789';
// export const Alpha = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz';
// export const AlphaNumeric = Alpha + Numeric;
