import { Collection } from 'backbone';
import config from '@/js/app/config';
import DealFileModel from '../models/file';

const DealFileCollection = Collection.extend({
    deal_id: null,

    url: function () {
        return config.api.url + 'deals/' + this.deal_id + '/files';
    },

    initialize: function (models, options) {
        this.deal_id = options.deal_id;
    },

    model: DealFileModel,
});

export default DealFileCollection;
