import { Model } from 'backbone';

export default Model.extend({
    getFormData: function (form) {
        console.debug('CriteriaModel#getFormData');

        const criteria = {};
        const formData = new FormData(form);
        formData.forEach((value, name) => {
            // If field name already string; convert to array
            if ('string' === typeof criteria[name]) {
                criteria[name] = [criteria[name]];
            }

            // If field name already array; push
            if (criteria[name] instanceof Array) {
                criteria[name].push(value);
            }
            // Else; Add key/value pair
            else {
                criteria[name] = value;
            }
        });

        return criteria;
    },
});
