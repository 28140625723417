import tinygradient from 'tinygradient';

export default {
    auth: {
        url: process.env.AUTH_URL || '',
        client_id: process.env.AUTH_CLIENT_ID || '',
        client_secret: '',
    },
    api: {
        url: process.env.API_URL || '',
    },
    image: {
        url: process.env.IMAGE_URL || '',
    },
    site: {
        en: process.env.SITE_HOST_EN || '',
        ja: process.env.SITE_HOST_JA || '',
    },
    rms_url: process.env.RMS_URL || '',
    brand_colors: tinygradient([
        '#8CC63E',
        '#2AB573',
        '#27A9E1',
        '#1B75BB',
        '#652C90',
    ]),
    enable_service_worker: JSON.parse(process.env.ENABLE_SERVICE_WORKER),
    google: {
        maps: {
            api_key: process.env.GOOGLE_MAPS_API_KEY || '',
        },
    },
};
