// import templateNotFound from '@/js/app/ui/templates/typeahead_notfound.html';
import { getCompanyName } from '@/js/app/formatter';

export default function (dataset) {
    return {
        name: 'companies',
        displayKey: (suggestion) => {
            return getCompanyName(suggestion);
        },
        source: dataset,
        limit: 10,
        // templates: {
        // 	notFound: templateNotFound({message: 'Unable to find any companies that match your search'}),
        // }
    };
}
