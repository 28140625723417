import { View } from 'backbone';
import PropertyLocationModel from '../models/location';
import template from '../templates/create.html';
import PropertyLocationView from './location';

export default class PropertyCreateView extends View {
    preinitialize() {
        this.subviews = {
            // Location subview
            location: new PropertyLocationView({
                model: new PropertyLocationModel(),
            }),
        };
    }

    initialize(options) {
        this.render();

        this.subviews.location.switchEditMode();

        // Set address using new property data
        this.subviews.location.changeAddress({
            ...options.new_property_data,
        });
    }

    render() {
        console.debug('PropertyCreateView#render');

        this.el.innerHTML = template();

        this.subviews.location
            .setElement(this.el.querySelector('#panel-location'))
            .render();
    }
}
