import { View } from 'backbone';
import BloodhoundStaffDataset from '@/js/app/bloodhound/datasets/staff';
import config from '@/js/app/config';
import { getName } from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Session from '@/js/app/session';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import { TypeaheadStaffDefaults } from '@/js/app/typeahead/defaults';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import ReportAccountingResultCollection from '../../accounting/collections/results';
import template from '../templates/generate.html';
import ReportStaffResultsView from './results';
import ReportStaffSummaryView from './summary';

export default class ReportStaffCriteriaView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'reports';

        this.events = {
            'submit #frm-report-criteria': this.generate,
        };

        // Override default criteria with criteria from options
        this.criteria = _.extend(
            {
                date_from: moment().format('YYYY-MM-01'),
                date_to: moment().format('YYYY-MM-') + moment().daysInMonth(),
                staff_id: 0,
            },
            options.criteria,
        );

        // Create typeahead subview
        this.subviews = {
            staffTypeahead: new UiTypeaheadView({
                placeholder: 'Agent',
                options: TypeaheadStaffDefaults,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundStaffDataset),
                ],
                formatter: getName,
                showDelete: Session.isAllowed('phnx:reports'),
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'generate');

        this.render();
    }

    render() {
        console.debug('ReportStaffCriteriaView#render');

        // Render template
        this.el.innerHTML = template({
            criteria: this.criteria,
        });

        this.subviews.staffTypeahead
            .setElement(this.$el.find('#cntr-staff_id'))
            .render();

        if (this.criteria.staff_id) {
            // Fetch information for given staff ID
            /** @todo Ideally we could set the ID in typeahead, and have it reverse lookup the staff, but Bloodhound isn't built for this */
            OAuth2Client.fetchJSON(
                config.api.url + 'staff/' + this.criteria.staff_id,
                {},
            ).then((response) => {
                // Set datum
                this.subviews.staffTypeahead.setDatum(response);

                // Trigger click on submit
                this.el.querySelector('button[type="submit"]').click();
            });
        }

        return this;
    }

    generate($e) {
        console.debug('ReportStaffCriteriaView#generate');

        $e.preventDefault();

        const criteria = {
            staff_id: this.subviews.staffTypeahead.datum
                ? this.subviews.staffTypeahead.datum.id
                : null,
        };

        // Get criteria from search form
        /** @todo This function should go in a library - could be useful elsewhere */
        this.$el
            .find('form')
            .serializeArray()
            .forEach((field) => {
                // If field not empty
                if ('' !== field.value) {
                    // If field name already string; convert to array
                    if ('string' === typeof criteria[field.name]) {
                        criteria[field.name] = [criteria[field.name]];
                    }

                    // If field name already object; add to array
                    if ('object' === typeof criteria[field.name]) {
                        criteria[field.name].push(field.value);
                    }
                    // Else; Add key/value pair
                    else {
                        criteria[field.name] = field.value;
                    }
                }
            });

        // Create new results collection
        const reportResults = new ReportAccountingResultCollection();

        // Create new summary view
        const staffSummaryView = new ReportStaffSummaryView({
            collection: reportResults,
        });

        // Attach staff summary view to DOM
        this.$el.find('#summary').html(staffSummaryView.el);

        // Create new results view
        const staffResultsView = new ReportStaffResultsView({
            collection: reportResults,
        });

        // Attach staff results view to DOM
        this.$el.find('#results').html(staffResultsView.el);

        // Fetch results
        reportResults.fetch({
            data: criteria,
            reset: true,
        });
    }
}
