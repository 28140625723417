import { View } from 'backbone';
import Texts from '@/js/app/generic/texts';
import template from '../templates/edit_location_stations.html';

export default class PropertyLocationStationsView extends View {
    preinitialize() {
        this.events = {
            'change input[data-group="station"]':
                this.handleChangeStationMinutes,
            'click ol.train_lines [data-action="delete-station"]':
                this.handleClickDeleteStation,
        };
    }

    render() {
        console.debug('PropertyLocationStationsView#render');

        this.el.innerHTML = template({
            stations: this.model.get('stations'),
            Texts,
        });

        this.#populateFormFields();
    }

    #populateFormFields() {
        const stations = this.model.get('stations');
        if (stations) {
            stations.forEach((station, i) => {
                this.el.querySelector(
                    `[data-station="${i}"] [name="min"]`,
                ).value = station.distance;
            });
        }
    }

    handleChangeStationMinutes(e) {
        console.debug('PropertyLocationView#handleChangeStationMinutes');

        e.preventDefault();

        // Get station element
        const stationEl = this.$el.find(e.currentTarget).closest('li');
        const index = stationEl.data('station');
        const minutes = Number(e.currentTarget.value);

        // Get copy of stations from model
        let stations = JSON.parse(JSON.stringify(this.model.get('stations')));

        // Adjust "distance" for relevant station
        stations[index].distance = minutes;

        // Sort stations by minutes from location
        stations = _.sortBy(stations, (item) => {
            return Number(item.distance);
        });

        // Set new stations array in model
        this.model.set('stations', stations);
    }

    handleClickDeleteStation(e) {
        console.debug('PropertyLocationView#handleClickDeleteStation');

        e.preventDefault();

        // Get station element
        const stationEl = this.$el.find(e.currentTarget).closest('li');

        // Get copy of stations from model
        let stations = JSON.parse(JSON.stringify(this.model.get('stations')));
        const index = stationEl.data('station');

        // Removes 1 item from array at position "id"
        stations.splice(index, 1);

        // Set new stations array in model
        this.model.set('stations', stations);
    }
}
