import { Collection } from 'backbone';
import config from '@/js/app/config';
import PeopleModel from '../models/people';

export default Collection.extend({
    url: config.api.url + 'people',

    model: PeopleModel,

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },
});
