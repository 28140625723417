import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasets/company';
import { getCompanyLink, getCompanyName } from '@/js/app/formatter';
import StaffCollection from '@/js/app/staff/collections/staff';
import * as TextHelper from '@/js/app/text-helper';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasources/company';
import { TypeaheadCompanyDefaults } from '@/js/app/typeahead/defaults';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import { View } from 'backbone';
import numeral from 'numeral';
import template from '../templates/edit_profile.html';
import PeopleEditProfileEdmView from './edit-profile-edm';

export default class PeopleEditProfileView extends View {
    preinitialize(options) {
        this.tagName = 'form';

        this.events = {
            'change input,select,textarea': this.updateModel,
            'typeahead:select #cntr-company input[type="text"]':
                this.selectCompany,
            'click #delete-company': this.clearCompany,
        };

        this.subviews = {
            company: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                formatter: getCompanyName,
                urlGenerator: getCompanyLink,
            }),
            salesStaff: new UiIndividualSelectorView({
                id: 'field-staff_sales',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_sales',
                },
                triggerChange: false,
            }),
            investmentSalesStaff: new UiIndividualSelectorView({
                id: 'field-staff_investment_sales',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_investment_sales',
                },
                triggerChange: false,
            }),
            leasingStaff: new UiIndividualSelectorView({
                id: 'field-staff_leasing',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_leasing',
                },
                triggerChange: false,
            }),
            pmStaff: new UiIndividualSelectorView({
                id: 'field-staff_pm',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_pm',
                },
                triggerChange: false,
            }),
            rhStaff: new UiIndividualSelectorView({
                id: 'field-staff_rh',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_rh',
                },
                triggerChange: false,
            }),
            edm: new PeopleEditProfileEdmView({
                peopleModel: options.model,
            }),
        };
    }

    initialize() {
        _.bindAll(this, 'selectCompany', 'clearCompany');

        this.listenTo(this.model, 'change', this.updateOutlets);
        this.listenTo(this.model, 'change:company', this.handleUpdateCompany);
        this.listenTo(this.model, 'change:email', this.handleEmailChange);
        this.listenTo(
            this.model,
            'change:telephone',
            this.handleTelephoneChange,
        );
        this.listenTo(
            this.model,
            'change:telephone_other',
            this.handleOtherTelChange,
        );
        this.listenTo(this.model, 'change:wechat_id', this.handleWeChatChange);
        this.listenTo(this.model, 'change:line_id', this.handleLineChange);
        this.listenTo(this.model, 'change:whatsapp', this.handleWhatsappChange);

        this.listenTo(
            this.model,
            'change:status_sales',
            this.handleStatusSalesChange,
        );
        this.listenTo(
            this.model,
            'change:staff_id_sales',
            this.handleStaffIdSalesChange,
        );
        this.listenTo(
            this.model,
            'change:staff_sales',
            this.handleStaffSalesChange,
        );

        this.listenTo(
            this.model,
            'change:status_investment_sales',
            this.handleStatusInvestmentSalesChange,
        );
        this.listenTo(
            this.model,
            'change:staff_id_investment_sales',
            this.handleStaffIdInvestmentSalesChange,
        );
        this.listenTo(
            this.model,
            'change:staff_investment_sales',
            this.handleStaffInvestmentSalesChange,
        );

        this.listenTo(
            this.model,
            'change:status_leasing',
            this.handleStatusLeasingChange,
        );
        this.listenTo(
            this.model,
            'change:staff_id_leasing',
            this.handleStaffIdLeasingChange,
        );
        this.listenTo(
            this.model,
            'change:staff_leasing',
            this.handleStaffLeasingChange,
        );

        this.listenTo(
            this.model,
            'change:status_pm',
            this.handleStatusPmChange,
        );
        this.listenTo(
            this.model,
            'change:staff_id_pm',
            this.handleStaffIdPmChange,
        );
        this.listenTo(this.model, 'change:staff_pm', this.handleStaffPmChange);

        this.listenTo(
            this.model,
            'change:status_rh',
            this.handleStatusRhChange,
        );
        this.listenTo(
            this.model,
            'change:staff_id_rh',
            this.handleStaffIdRhChange,
        );
        this.listenTo(this.model, 'change:staff_rh', this.handleStaffRhChange);
    }

    render() {
        console.debug('PeopleEditProfileView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            nationality: TextHelper._source.country,
            country: TextHelper._source.country,
            isKamisama: false,
        });

        // Attach subviews
        this.subviews.edm.setElement(
            this.el.querySelector('[data-slot="edm"]'),
        );

        // Inject subviews
        this.$el.find('#cntr-company').html(this.subviews.company.render().el);
        this.$el.find('#cntr-staff_sales').html(this.subviews.salesStaff.el);
        this.$el
            .find('#cntr-staff_investment_sales')
            .html(this.subviews.investmentSalesStaff.el);
        this.$el
            .find('#cntr-staff_leasing')
            .html(this.subviews.leasingStaff.el);
        this.$el.find('#cntr-staff_pm').html(this.subviews.pmStaff.el);
        this.$el.find('#cntr-staff_rh').html(this.subviews.rhStaff.el);

        // Fetch staff
        this.subviews.salesStaff.collection.fetch({
            data: {
                division_id: 2,
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        this.subviews.investmentSalesStaff.collection.fetch({
            data: {
                division_id: 11,
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        this.subviews.leasingStaff.collection.fetch({
            data: {
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        this.subviews.pmStaff.collection.fetch({
            data: {
                division_id: 3,
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        this.subviews.rhStaff.collection.fetch({
            data: {
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });

        // Setup typeahead
        this.$el
            .find('#field-company')
            .typeahead(
                TypeaheadCompanyDefaults,
                typeaheadCompanyDatasource(BloodhoundCompanyDataset),
            );

        // Add placeholder to typeahead fields
        this.$el.find('.tt-input').prop('placeholder', '\uf002');

        return this;
    }

    updateModel($e) {
        console.debug('PeopleEditProfileView#updateModel');

        const t = $e.currentTarget;

        // If no name, return
        if (t.name === '') {
            return;
        }

        let value;
        // If type is checkbox; get property "checked"
        if (t.type === 'checkbox') {
            value = t.checked;
        }
        // Else if number type, or data attribute "numeral" exists; unformat number
        else if (t.type === 'number' || t.dataset.numeral) {
            value = numeral(t.value).value() || null;
        }
        // Else; use value
        else {
            if (t.value === '') {
                value = null;
            } else {
                value = t.value;
            }
        }

        // Save new value
        this.model.save(t.name, value, { patch: true, wait: true });
    }

    updateOutlets(model) {
        console.debug('PeopleEditProfileView#updateOutlets');

        // Loop through all changed attributes, and set value of input fields
        /** @todo What if <select> or <textarea>? */
        _.each(model.changedAttributes(), (v, k) => {
            const t = this.$el.find('[name="' + k + '"]');

            // If radio or checkbox, change checked property
            if (t.prop('type') === 'radio') {
                this.$el
                    .find('input[name="' + k + '"][value="' + v + '"]')
                    .prop('checked', true);
            } else if (t.prop('type') === 'checkbox') {
                this.$el.find('input[name="' + k + '"]').prop('checked', v > 0); // Only positive values are considered checked
            }
            // Else, change value
            else {
                // If numeral data attribute exists, format appropriately
                if (t.data('numeral')) {
                    t.val(v ? numeral(v).format(t.data('numeral')) : '');
                } else {
                    t.val(v);
                }
            }

            if (k === 'assigned_date_sales') {
                this.updateAssignedDate('sales', v);
            } else if (k === 'assigned_date_investment_sales') {
                this.updateAssignedDate('investmentSales', v);
            } else if (k === 'assigned_date_leasing') {
                this.updateAssignedDate('leasing', v);
            } else if (k === 'assigned_date_pm') {
                this.updateAssignedDate('pm', v);
            } else if (k === 'assigned_date_rh') {
                this.updateAssignedDate('rh', v);
            }
        });
    }

    handleEmailChange(model, value) {
        console.debug('PeopleEditProfileView#handleEmailChange');

        this.updateEmailLink(value);
    }

    updateEmailLink(value) {
        console.debug('PeopleEditProfileView#updateEmailLink');

        const el = this.$el.find('#link-email');
        el.prop('href', 'mailto:' + value);
        if (_.isEmpty(value)) {
            el.hide();
        } else {
            el.show();
        }
    }

    handleTelephoneChange(model, value) {
        console.debug('PeopleEditProfileView#handleTelephoneChange');

        this.updateTelephoneLink(value);
    }

    updateTelephoneLink(value) {
        console.debug('PeopleEditProfileView#updateTelephoneLink');

        const el = this.$el.find('#link-tel');
        el.prop('href', 'tel:' + value);
        if (_.isEmpty(value)) {
            el.hide();
        } else {
            el.show();
        }
    }

    handleOtherTelChange(model, value) {
        console.debug('PeopleEditProfileView#handleOtherTelChange');

        this.updateOtherTelLink(value);
    }

    updateOtherTelLink(value) {
        console.debug('PeopleEditProfileView#updateOtherTelLink');

        const el = this.$el.find('#link-other_tel');
        el.prop('href', 'tel:' + value);
        if (_.isEmpty(value)) {
            el.hide();
        } else {
            el.show();
        }
    }

    handleWeChatChange(model, value) {
        console.debug('PeopleEditProfileView#handleWeChatChange');

        this.updateWeChatLink(value);
    }

    updateWeChatLink(value) {
        console.debug('PeopleEditProfileView#updateWeChatLink');

        const el = this.$el.find('#link-wechat');
        el.prop('href', 'weixin://contacts/profile/' + value);
        if (_.isEmpty(value)) {
            el.hide();
        } else {
            el.show();
        }
    }

    handleLineChange(model, value) {
        console.debug('PeopleEditProfileView#handleLineChange');

        this.updateLineLink(value);
    }

    updateLineLink(value) {
        console.debug('PeopleEditProfileView#updateLineLink');

        const el = this.$el.find('#link-line');
        el.prop('href', 'https://line.me/R/ti/p/' + value);
        if (_.isEmpty(value)) {
            el.hide();
        } else {
            el.show();
        }
    }

    handleWhatsappChange(model, value) {
        console.debug('PeopleEditProfileView#handleWhatsappChange');

        this.updateWhatsappLink(value);
    }

    updateWhatsappLink(value) {
        console.debug('PeopleEditProfileView#updateWhatsappLink');

        const el = this.$el.find('#link-whatsapp');
        el.prop('href', 'https://wa.me/' + value);
        if (_.isEmpty(value)) {
            el.hide();
        } else {
            el.show();
        }
    }

    handleUpdateCompany(model, value) {
        console.debug('PeopleEditProfileView#handleUpdateCompany');

        this.updateCompany(value);
    }

    updateCompany(value) {
        console.debug('PeopleEditProfileView#updateCompany');

        if (value) {
            this.subviews.company.setDatum(value);
        }
    }

    selectCompany($e, datum) {
        console.debug('PeopleEditProfileView#selectCompany');

        // Save new company ID
        this.model.save('company_id', datum.id, { patch: true, wait: true });
    }

    clearCompany($e) {
        console.debug('PeopleEditProfileView#clearCompany');

        $e.preventDefault();

        this.model.save('company_id', null, { patch: true, wait: true });
    }

    handleStatusSalesChange(model, value) {
        console.debug('PeopleEditProfileView#handleStatusSalesChange');

        this.updateAssignedStaffElement('sales', value);
    }

    handleStaffIdSalesChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffIdSalesChange');

        this.subviews.salesStaff.setSelected(value);
    }

    handleStaffSalesChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffSalesChange');

        this.subviews.salesStaff.collection.add(value);
    }

    handleStatusInvestmentSalesChange(model, value) {
        console.debug(
            'PeopleEditProfileView#handleStatusInvestmentSalesChange',
        );

        this.updateAssignedStaffElement('investment_sales', value);
    }

    handleStaffIdInvestmentSalesChange(model, value) {
        console.debug(
            'PeopleEditProfileView#handleStaffIdInvestmentSalesChange',
        );

        this.subviews.investmentSalesStaff.setSelected(value);
    }

    handleStaffInvestmentSalesChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffInvestmentSalesChange');

        this.subviews.investmentSalesStaff.collection.add(value);
    }

    handleStatusLeasingChange(model, value) {
        console.debug('PeopleEditProfileView#handleStatusLeasingChange');

        this.updateAssignedStaffElement('leasing', value);
    }

    handleStaffIdLeasingChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffIdLeasingChange');

        this.subviews.leasingStaff.setSelected(value);
    }

    handleStaffLeasingChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffLeasingChange');

        this.subviews.leasingStaff.collection.add(value);
    }

    handleStatusPmChange(model, value) {
        console.debug('PeopleEditProfileView#handleStatusPmChange');

        this.updateAssignedStaffElement('pm', value);
    }

    handleStaffIdPmChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffIdPmChange');

        this.subviews.pmStaff.setSelected(value);
    }

    handleStaffPmChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffPmChange');

        this.subviews.pmStaff.collection.add(value);
    }

    handleStatusRhChange(model, value) {
        console.debug('PeopleEditProfileView#handleStatusRhChange');

        this.updateAssignedStaffElement('rh', value);
    }

    handleStaffIdRhChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffIdRhChange');

        this.subviews.rhStaff.setSelected(value);
    }

    handleStaffRhChange(model, value) {
        console.debug('PeopleEditProfileView#handleStaffRhChange');

        this.subviews.rhStaff.collection.add(value);
    }

    updateAssignedStaffElement(division, value) {
        console.debug('PeopleEditProfileView#updateAssignedStaffElement');

        const staffField = this.el.querySelector(`#field-staff_${division}`);

        if (value === 'unassigned') {
            staffField.disabled = true;
            staffField.value = '';

            if (division === 'sales') {
                this.el.querySelector(`#field-last_replied_date`).disabled =
                    true;
            }
        } else {
            staffField.disabled = false;

            if (division === 'sales') {
                this.el.querySelector(`#field-last_replied_date`).disabled =
                    false;
            }
        }
    }

    updateAssignedDate(division, value) {
        console.debug('PeopleEditProfileView#updateAssignedDate');

        this.el.querySelector(`#${division}StaffAssignedDate`).innerText = value
            ? moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss')
            : '';
    }
}
