import { getName } from '@/js/app/formatter';
import DynamicSelect from './dynamic-select';

export default class IndividualSelector extends DynamicSelect {
    preinitialize(options) {
        options.mapper = function (model) {
            return {
                label: getName(model.toJSON()),
                value: model.get('id'),
            };
        };

        // Force add data-number attribute
        options.attributes['data-number'] = '';

        options.prependOptions = [{ label: '', value: '' }].concat(
            options.prependOptions || [],
        );

        super.preinitialize(options);
    }
}
