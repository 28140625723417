import { Collection } from 'backbone';
import config from '@/js/app/config';
import PeopleModel from '@/js/app/people/models/people';

export default Collection.extend({
    url: function () {
        return config.api.url + 'units/' + this.unit_id + '/automatch';
    },

    initialize: function (models, options = {}) {
        this.unit_id = options.unit_id || null;
    },

    model: PeopleModel,
});
