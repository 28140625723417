module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (!_.isEmpty(buttons)) { ;
__p += '\n<div class="actionsBar mb-3">\n\n';
 if (buttons.add_to_list) { ;
__p += '\n    ';
 if (buttons.type === 'building') { ;
__p += '\n        <a href="#buildings/lists/add" class="btn btn-sm btn-secondary" data-button-requires="selection" data-url="property/lists/add">Add to List</a>\n    ';
 } else { ;
__p += '\n        <a href="#property/lists/add" class="btn btn-sm btn-secondary" data-button-requires="selection" data-url="property/lists/add">Add to List</a>\n    ';
 } ;
__p += '\n';
 } ;
__p += '\n\n';
 if (buttons.remove_from_list) { ;
__p += '\n    <button type="button" class="btn btn-sm btn-secondary" data-button-requires="selection" data-action="remove-property" data-list_id="' +
((__t = ( buttons.list_id )) == null ? '' : __t) +
'">Remove from List</button>\n';
 } ;
__p += '\n\n';
 if (buttons.send) { ;
__p += '\n    ';
 if (buttons.type === 'building') { ;
__p += '\n        <a href="#buildings/send" class="btn btn-sm btn-secondary" data-button-requires="selection">Send Selected</a>\n    ';
 } else { ;
__p += '\n        <a href="#property/send" class="btn btn-sm btn-secondary" data-button-requires="selection">Send Selected</a>\n    ';
 } ;
__p += '\n';
 } ;
__p += '\n\n';
 if (buttons.print) { ;
__p += '\n    <button type="button" class="btn btn-sm btn-secondary" data-action="property-print" data-button-requires="selection">Print Selected</button>\n';
 } ;
__p += '\n\n    <!-- <a href="#property/marketing" class="btn btn-sm btn-secondary" data-button-requires="selection" data-url="property/marketing">Marketing</a> -->\n<!--\n';
 if (buttons.export) { ;
__p += '\n    <a href="#property/export" class="btn btn-sm btn-secondary" alt="Export results" title="Export results" data-url="property/export">Export Results</a>\n';
 } ;
__p += '\n-->\n\n';
 if (buttons.download_comparison) { ;
__p += '\n    <button type="button" class="btn btn-sm btn-secondary" data-button-requires="selection" data-action="download-comparison">Download Comparison</button>\n';
 } ;
__p += '\n\n';
 if (buttons.bulkedit) { ;
__p += '\n    <button type="button" class="btn btn-sm btn-secondary pull-right" data-action="bulkedit">Bulk Edit</a>\n';
 } ;
__p += '\n\n</div>\n';
 } ;


}
return __p
};
