import DivisionsCollection from '@/js/app/division/collections/divisions';
import Preferences from '@/js/app/preferences';
import StaffCollection from '@/js/app/staff/collections/staff';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import DealCollection from '../collections/deals';
import DealCriteria from '../criterias/deal';
import template from '../templates/search.html';
import DealResultsView from './results';

export default class DealSearchView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'deal';

        this.events = {
            'submit form': this.handleSubmit,
            'click [data-action="reset"]': this.handleReset,
        };

        // Create criteria model
        this.criteria = new DealCriteria();

        this.staffCollection = new StaffCollection();

        this.dealCollection = new DealCollection();

        // Create subviews
        this.subviews = {
            staffSelect: new UiIndividualSelectorView({
                id: 'field-staff_id',
                collection: this.staffCollection,
                attributes: {
                    name: 'staff_id',
                },
            }),
            divisionSelect: new UiDynamicSelectView({
                id: 'field-division',
                collection: new DivisionsCollection(),
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'division_id',
                },
                prependOptions: [{ label: '', value: '' }],
            }),
            dealResults: new DealResultsView({
                collection: this.dealCollection,
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'handleSubmit');

        this.render();

        // Fetch staff
        this.staffCollection.fetch({
            data: {
                order: 'last_name',
            },
        });

        // Fetch divisions
        this.subviews.divisionSelect.collection.fetch({
            data: {
                company_id: 1,
                active: 1,
                order: 'order',
            },
        });
    }

    render() {
        console.debug('DealSearchView#render');

        // Render template
        this.el.innerHTML = template({
            Preferences: Preferences,
        });

        // Append staffSelect subview inside container in DOM
        this.$el.find('#cntr-staff_id').html(this.subviews.staffSelect.el);

        // Attach divisionSelect subview inside container in DOM
        this.$el.find('#cntr-division').html(this.subviews.divisionSelect.el);

        // Attach dealResults subview to DOM
        this.subviews.dealResults.setElement(this.el.querySelector('#results'));

        // Get search criteria from sessionStorage
        this.dealSearchCriteria = JSON.parse(
            sessionStorage.getItem('dealSearchCriteria'),
        );

        // If search conditions exists
        if (this.dealSearchCriteria) {
            const { staff_id } = this.dealSearchCriteria;
            populateForm(
                this.el.querySelector('#frm-deal-search'),
                this.dealSearchCriteria,
            );
            this.subviews.staffSelect.setSelected(staff_id);
        }

        return this;
    }

    handleSubmit($e) {
        console.debug('DealSearchView#handleSubmit');

        $e.preventDefault();

        // Get defaults, and merge with criteria from form
        const criteria = this.criteria.defaults();

        const form = this.el.querySelector('#frm-deal-search');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        sessionStorage.setItem('dealSearchCriteria', JSON.stringify(criteria));

        // Clear existing criteria, and set new criteria
        this.criteria.clear({ silent: true }).set(criteria);
    }

    handleReset(e) {
        e.preventDefault();

        this.el.querySelector('#frm-deal-search').reset();
        sessionStorage.removeItem('dealSearchCriteria');
    }
}
