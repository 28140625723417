import formToObject from '@/js/libs/form-utils';
import { View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import PropertyListCollection from '../collections/property-lists';
import PropertyListCriteria from '../criterias/property-list';
import PropertyListModel from '../models/property-list';
import template from '../templates/search.html';
import PropertyListNewView from './new';
import PropertyListResultsView from './results';

export default class PropertyListSearchView extends View {
    preinitialize(options) {
        switch (options.visibility) {
            case 'mine':
                this.visibility = 'private';
                break;

            case 'shared':
                this.visibility = 'shared';
                break;

            case 'public':
                this.visibility = 'public';
                break;

            default:
                throw Error(
                    'Unknown visibility option, or visibility option not provided',
                );
        }

        this.events = {
            'submit form': this.handleSubmit,
            'click #btn-new_list': this.newList,
            'click [data-action="reset"]': this.handleReset,
        };

        // Create criteria model
        this.criteria = new PropertyListCriteria();

        // Create subviews
        this.subviews = {
            results: new PropertyListResultsView({
                collection: new PropertyListCollection(),
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'handleSubmit');

        this.render();
    }

    render() {
        console.debug('PropertyListSearchView#render');

        // Render template
        this.el.innerHTML = template({
            visibility: this.visibility,
        });

        // Bind results view
        this.subviews.results.setElement(this.$el.find('#results'));

        // Get search criteria from sessionStorage
        this.propertyListSearchCriteria = JSON.parse(
            sessionStorage.getItem('propertyListSearchCriteria'),
        );

        // If search conditions exists
        if (this.propertyListSearchCriteria) {
            populateForm(
                this.el.querySelector('#frm-list-search'),
                this.propertyListSearchCriteria,
            );
        }

        // Trigger submit on form
        this.$el.find('form').trigger('submit');

        return this;
    }

    handleSubmit($e) {
        console.debug('PropertyListSearchView#handleSubmit');

        $e.preventDefault();

        // Get defaults, and merge with criteria from form
        const criteria = this.criteria.defaults();

        const form = this.el.querySelector('#frm-list-search');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        sessionStorage.setItem(
            'propertyListSearchCriteria',
            JSON.stringify(criteria),
        );

        // Clear existing criteria, and set new criteria
        this.criteria.clear({ silent: true }).set(criteria);
    }

    handleReset(e) {
        e.preventDefault();

        sessionStorage.removeItem('propertyListSearchCriteria');
        this.el.querySelector('form').reset();
    }

    newList() {
        console.debug('PropertyListSearchView#newList');

        // Create view with model, depending on visibility
        new PropertyListNewView({
            visibility: this.visibility,
            model: new PropertyListModel({
                visibility: this.visibility,
            }),
        }).render();
    }
}
