import { Model } from 'backbone';
import config from '@/js/app/config';

export default Model.extend({
    urlRoot: config.api.url + 'staff',

    // Validate attributes
    validate: function (attrs) {
        console.debug('StaffModel#validate');

        // If model is NOT new, don't validate
        /** @todo Remove this, and display validation results (may work better once we have a save button) */
        if (!this.isNew()) {
            return null;
        }

        const errors = {};

        // Ensure first name not blank
        if (_.isEmpty(attrs.first_name)) {
            errors.first_name = 'First name cannot be blank';
        }

        // Ensure last name not blank
        if (_.isEmpty(attrs.last_name)) {
            errors.last_name = 'Last name cannot be blank';
        }

        return _.isEmpty(errors) ? null : errors;
    },

    // Default attributes for the people item
    defaults: function () {
        return {
            id: null,
        };
    },
});
