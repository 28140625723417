import { Events } from 'backbone';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';

export default class ReportMotozukeResultsCollection {
    constructor() {
        _.extend(this, Events);
        this.data = [];
    }

    fetch(options) {
        this.trigger('request');

        OAuth2Client.ajax(
            _.extend(
                {
                    url: config.api.url + 'reports/motozuke',
                    dataType: 'json',
                },
                options,
            ),
        ).then((response) => {
            this.data = response;

            // Add cover photo image URL
            this.data = this.data.map((row) => {
                row.cover_photo_image = `${config.image.url}b/${row.building_id}/${Formatter.getCoverPhotoFilename(row.images)}`;
                return row;
            });

            this.trigger('change');

            this.trigger('sync');
        });
    }

    toJSON() {
        return this.data;
    }

    sort(key, order) {
        /** @todo Move into a parent class or library function? */
        this.data = this.data.sort((a, b) => {
            if (a[key] === b[key]) {
                return 0;
            } else {
                if (order === 'asc') {
                    return a[key] > b[key] ? 1 : -1;
                } else {
                    return a[key] < b[key] ? 1 : -1;
                }
            }
        });
    }
}
